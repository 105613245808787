import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_OP_READ_ACTION = 'API_OP_READ_ACTION';
export const API_OP_FINALIZE_ACTION = 'API_OP_FINALIZE_ACTION';
export const API_OP_LIST_ACTION = 'API_OP_LIST_ACTION';
export const API_OP_CREATE_ACTION = 'API_OP_CREATE_ACTION';
export const API_OP_UPDATE_ACTION = 'API_OP_UPDATE_ACTION';

export const op_read = (op_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_OP_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.op_read(op_id),
            API_OP_READ_ACTION,
            callback
        );
    }
}

export const op_finalize = (op_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_OP_FINALIZE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.op_finalize(op_id, body),
            API_OP_FINALIZE_ACTION,
            callback
        );
    }
}

export const op_update = (op_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_OP_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.op_update(op_id, body),
            API_OP_UPDATE_ACTION,
            callback
        );
    }
}

export const op_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_OP_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.op_create({ ...body }),
            API_OP_CREATE_ACTION,
            callback
        );
    }
}


export const op_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_OP_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.op_list(meta),
            API_OP_LIST_ACTION,
            callback
        );
    }
}
