import { combineReducers } from 'redux'
import { loggedInUser } from 'layouts/landing/reducers/login_reducer';
import { loadingReducer } from 'layouts/main/reducers/loading_reducer';
import { errorReducer } from 'layouts/main/reducers/error_reducer';
import { fundclusterListReducer } from 'modules/accounting/reducers/fundcluster_reducers';
import { accountListReducer } from 'modules/accounting/reducers/account_reducers';
import { taxListReducer } from 'modules/accounting/reducers/tax_reducers';
import { bankListReducer } from 'modules/accounting/reducers/bank_reducers';
import { voucherListReducer } from 'modules/voucher/reducers/voucher_reducers';
import { receiptListReducer } from 'modules/collection/reducers/receipt_reducers';
import { papListReducer } from 'modules/budget/reducers/pap_reducers';
import { burListReducer } from 'modules/budget/reducers/bur_reducers';
import { journalListReducer } from 'modules/journal/reducers/journal_reducers'
import partnerReducer from 'modules/partner/reducers';
import notification from 'modules/notification/reducers'
// import opReducer from 'modules/collection/_op/reducers';
import { opListReducer } from 'modules/collection/reducers/op_reducers';

const appReducer = combineReducers({
    loggedInUser: loggedInUser,
    loadingReducer: loadingReducer,
    errorReducer: errorReducer,
    fundclusterListReducer,
    accountListReducer,
    taxListReducer,
    bankListReducer,
    voucherListReducer,
    receiptListReducer,
    burListReducer,
    papListReducer,
    journalListReducer,
    partner: partnerReducer,
    // order_of_payment: opReducer,
    opListReducer: opListReducer,
    notification
});

// Clear all states after the user logs out
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        console.log("LOGOUT: clearing global state...");
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;
