import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_BUR_CREATE_ACTION = 'API_BUR_CREATE_ACTION';
export const API_BUR_READ_ACTION = 'API_BUR_READ_ACTION';
export const API_BUR_UPDATE_ACTION = 'API_BUR_UPDATE_ACTION';
export const API_BUR_DELETE_ACTION = 'API_BUR_DELETE_ACTION';
export const API_BUR_LIST_ACTION = 'API_BUR_LIST_ACTION';

export const bur_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BUR_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.bur_create(body),
            API_BUR_CREATE_ACTION,
            callback
        );
    }
}

export const bur_read = (bur_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BUR_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.bur_read(bur_id),
            API_BUR_READ_ACTION,
            callback
        );
    }
}

export const bur_update = (bur_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BUR_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.bur_update(bur_id, body),
            API_BUR_UPDATE_ACTION,
            callback
        );
    }
}

export const bur_delete = (bur_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BUR_DELETE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.bur_delete(bur_id),
            API_BUR_DELETE_ACTION,
            callback
        );
    }
}

export const bur_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BUR_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.bur_list(meta),
            API_BUR_LIST_ACTION,
            callback
        );
    }
}
