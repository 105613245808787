export default {
    drawer: {
        height: 'calc(100% - 115px)',
        overflow: 'auto',
        marginBottom: 55,
        marginTop: 60
    },
    drawer_bottom: { 
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 55
    },
    drawer_top: {
        position: 'absolute',
        top: 0,
        width: '100%',
        borderBottom: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        height: 60,
        fontSize: 24
    }

}