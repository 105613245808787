import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row, notification } from 'antd';
import { NavLink } from 'react-router-dom';
// import ReportForm from './form_Report';
// import { download_url } from 'shared/utils/api_utils';

import ReportForm from './form_Report';
import { download_url, getError } from 'shared/utils/api_utils';
import API_CLIENT from 'shared/utils/api_client';


class MyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            report_title: "General Ledger",
            loading: false,
        }
    }


    loadData = (params) => {
        API_CLIENT.report_generalledger(params)
            .then((response) => {
                console.log(response);
                this.setState({data: response.data});
                if (response.data.data.length === 0) {
                    notification['error']({ message: 'No match found' });
                }
            })
            .catch((error) => {
                notification['error']({ message: getError(error).message });
            });
    }

    handleClick = () => {
        this.doIt(false);
    }

    download = () => {
        this.doIt(true);
    }

    doIt = (is_download) => {
        this.formRef.props.form.validateFields((err, values) => {
            if (!err) {
                if (is_download) {
                    console.log('Download');
                    download_url(
                        '/report/pdf/general_ledger' +
                        '?start_date=' + values.start_date.format('YYYY-MM-DD') +
                        '&end_date=' + values.end_date.format('YYYY-MM-DD') +
                        '&account_id=' + values.account_id
                        , 'general_ledger.pdf');
                }
                else {
                    this.loadData({
                        ...values,
                        start_date: values.start_date.format('YYYY-MM-DD'),
                        end_date: values.end_date.format('YYYY-MM-DD')
                    });    
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%', }}>
                <Row><h1>{this.state.report_title}</h1></Row>
                <Row>
                    <Col span={12}>
                        <ReportForm
                            wrappedComponentRef={(formRef) => { this.formRef = formRef; }} />
                    </Col>
                    <Col span={12} >
                        <p style={{ textAlign: 'right' }}>
                            <Button >
                                <NavLink to="/report">Back to reports</NavLink>
                            </Button> &nbsp;
                          <Button disabled={true} type="primary" onClick={this.handleClick}>Generate</Button> &nbsp;
                          <Button type="primary" onClick={this.download}>Download</Button>
                        </p>
                    </Col>
                </Row>

                <Row style={{borderBottom: '1px solid #ccc'}}>
                </Row>
                {
                    //     this.state.data.length > 0 &&
                    //     <TableLedger
                    //         data={this.state.data}
                    //         loading={this.state.loading}
                    //     />
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
    };
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
