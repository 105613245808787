import React, { Component } from 'react';
import { Input, Col, Row, Select, notification, Icon } from 'antd';
import API_CLIENT from 'shared/utils/api_client';
const Option = Select.Option;



class burAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || [{ pap_code: null, pap_id: null, amount: null }],
            pap_options: []
        }
    }

    // /for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || [{ pap_code: null, pap_id: null, amount: null }]
            });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps && prevProps.year !== this.props.year) {
            this.getPapList();
        }
    }
    
    componentDidMount = () => {
        console.log('componentDidMount');
        this.getPapList();
    }

    getPapList = () => {
        API_CLIENT.pap_list({ take: 100000, is_active: [1], pap_year: [this.props.year] })
            .then((response) => {
                this.setState({pap_options: response.data.data});
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
    }

    add = () => {
        const value = this.state.value;
        value.push({ pap_code: null, pap_id: null, amount: null })
        this.setState({ value });
    }

    removeItem = (index) => {
        const arr = this.state.value;
        arr.splice(index, 1);
        this.setState({value: arr});
        this.props.onChange(arr);
    }

    updateItem = (index, value) => {
        const arr = this.state.value;
        arr[index] = value;
        this.setState({value: arr});
        this.props.onChange(arr);
    }

    onPAPCodeChange = (index, value) => {
        var objectToBeChanged = this.state.value[index];
        objectToBeChanged = { ...objectToBeChanged, pap_code: value };
        this.updateItem(index, objectToBeChanged);
    }

    onPAPIdChange = (index, value) => {
        var objectToBeChanged = this.state.value[index];
        objectToBeChanged = { ...objectToBeChanged, pap_id: value };
        this.updateItem(index, objectToBeChanged);
    }

    onAmountChange = (index, e) => {
        var objectToBeChanged = this.state.value[index];
        objectToBeChanged = { ...objectToBeChanged, amount: e.target.value };
        this.updateItem(index, objectToBeChanged);
    }

    render() {
        var pap_code_options = [];
        this.state.pap_options.map((item) => {
            if (!pap_code_options.includes(item.pap_code)) {
                pap_code_options.push(item.pap_code)
            }
            return null;
        })
        console.log(this.state.value, this.state.pap_options);
        return (
            <div>
                {
                    this.state.value.map((val, index) => {
                        var pap_id_options = this.state.pap_options;
                        
                        pap_id_options = pap_id_options.filter((item) => {
                            return item.pap_code === val.pap_code;
                        })

                        return (
                            <Row gutter={16} key={index} style={{marginBottom: 10}}>
                                <Col span={6}>
                                    
                                    <Select mode='default' value={val.pap_code} onChange={value => this.onPAPCodeChange(index, value)} placeholder="PAP Code">
                                        {
                                            pap_code_options.map(function (item) {
                                                return <Option key={item} value={item}>
                                                    {item} </Option>
                                            })
                                        }
                                    </Select>
                                
                                </Col>

                                <Col span={10}>
                                    
                                    <Select mode='default' value={val.pap_id} onChange={value => this.onPAPIdChange(index, value)}  placeholder='Project'>
                                        {
                                            pap_id_options.map(function (item) {
                                                return <Option key={item.id} value={item.id}>
                                                    {item.pap_program} </Option>
                                            })
                                        }
                                    </Select>
                                    
                                </Col>

                                <Col span={7}>
                                    
                                    <Input value={val.amount} onChange={value => this.onAmountChange(index, value)} placeholder='Amount'/>
                                    
                                </Col>
                                <Col span={1}>
                                    <p style={{paddingTop: 6}}><Icon type="close" style={{ color: "red" }} onClick={() => this.removeItem(index)} /></p>
                                </Col>
                            </Row>
                        );
                    })
                }
                <Row gutter={16}>
                    <Col>
                        <button className="link-button"
                            type="default"
                            onClick={this.add}
                            > Add more projects
                        </button>
                    </Col>
                 </Row>
            </div>
        );
    }
}


export default burAdd;
