import React, { Component } from 'react';
import { DatePicker, Form, Col, Row, Switch, notification } from 'antd';
import API_CLIENT from 'shared/utils/api_client';
import moment from 'moment';

const WrappedForm = Form.create()(
    class extends Component {
        toggleOpen = (value, e) => {
            //e.preventDefault();

            // update server setting

            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({ is_open: value });
                    API_CLIENT.config_update('trial_balance', {
                        value: {
                            ...values,
                            start_date: values.start_date.format('YYYY-MM-DD'),
                            end_date: values.end_date.format('YYYY-MM-DD'),
                            is_open: value
                        }
                    })
                        .then((response) => {
                            this.setState({ ...response.data.value, loading: false });
                            this.props.form.setFieldsValue({
                                start_date: moment(response.data.value.start_date),
                                end_date: moment(response.data.value.end_date),
                                is_open: response.data.value.is_open
                            })
                        })
                        .catch((error) => {
                            notification['error']({ message: error.message });
                        });
                }
                else {
                    console.log('false');
                    this.setState({ loading: true });
                    setTimeout(() => {
                        this.props.form.setFieldsValue({
                            is_open: false
                        })
                        this.setState({ loading: false });
                    }, 10);
                    notification['error']({ message: 'Please input all values' });
                }
            });



        }

        constructor(props) {
            super(props);
            this.state = {
                start: null,
                end: null,
                is_open: false,
                loading: false
            }
        }

        componentDidMount() {
            this.setState({ loading: true })
            API_CLIENT.config_read('trial_balance', {})
                .then((response) => {
                    this.setState({ ...response.data.value, loading: false });
                    this.props.form.setFieldsValue({
                        start_date: moment(response.data.value.start_date),
                        end_date: moment(response.data.value.end_date),
                        is_open: response.data.value.is_open
                    })
                })
                .catch((error) => {
                    notification['error']({ message: error.message });
                });
        }

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date" disabled={this.state.is_open} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date" disabled={this.state.is_open} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >

                                {
                                    getFieldDecorator('is_open', {
                                        rules: [{ required: false, message: 'Open' }],
                                        valuePropName: 'checked'
                                    })(
                                        <Switch loading={this.state.loading} onChange={(value, e) => this.toggleOpen(value, e)} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            );
        }
    }
);

export default WrappedForm;