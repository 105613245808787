import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_PAP_CREATE_ACTION = 'API_PAP_CREATE_ACTION';
export const API_PAP_READ_ACTION = 'API_PAP_READ_ACTION';
export const API_PAP_UPDATE_ACTION = 'API_PAP_UPDATE_ACTION';
export const API_PAP_DELETE_ACTION = 'API_PAP_DELETE_ACTION';
export const API_PAP_LIST_ACTION = 'API_PAP_LIST_ACTION';

export const pap_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_PAP_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.pap_create(body),
            API_PAP_CREATE_ACTION,
            callback
        );
    }
}

export const pap_read = (pap_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_PAP_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.pap_read(pap_id),
            API_PAP_READ_ACTION,
            callback
        );
    }
}

export const pap_update = (pap_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_PAP_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.pap_update(pap_id, body),
            API_PAP_UPDATE_ACTION,
            callback
        );
    }
}

export const pap_delete = (pap_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_PAP_DELETE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.pap_delete(pap_id),
            API_PAP_DELETE_ACTION,
            callback
        );
    }
}

export const pap_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_PAP_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.pap_list(meta),
            API_PAP_LIST_ACTION,
            callback
        );
    }
}
