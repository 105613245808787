import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_TAX_CREATE_ACTION = 'API_TAX_CREATE_ACTION';
export const API_TAX_READ_ACTION = 'API_TAX_READ_ACTION';
export const API_TAX_UPDATE_ACTION = 'API_TAX_UPDATE_ACTION';
export const API_TAX_DELETE_ACTION = 'API_TAX_DELETE_ACTION';
export const API_TAX_LIST_ACTION = 'API_TAX_LIST_ACTION';

export const tax_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_TAX_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_create(body),
            API_TAX_CREATE_ACTION,
            callback
        );
    }
}

export const tax_read = (tax_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_TAX_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_read(tax_id),
            API_TAX_READ_ACTION,
            callback
        );
    }
}

export const tax_update = (tax_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_TAX_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_update(tax_id, body),
            API_TAX_UPDATE_ACTION,
            callback
        );
    }
}

export const tax_delete = (tax_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_TAX_DELETE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_delete(tax_id),
            API_TAX_DELETE_ACTION,
            callback
        );
    }
}

export const tax_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_TAX_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_list(meta),
            API_TAX_LIST_ACTION,
            callback
        );
    }
}
