import { API_FUNDCLUSTER_LIST_ACTION, API_FUNDCLUSTER_UPDATE_ACTION, API_FUNDCLUSTER_CREATE_ACTION } from '../actions/fundcluster_actions'

export const fundclusterListReducer = (state = {}, action) => {
    var newData;
    switch (action.type) {
        //case `${API_CASE_LIST_ACTION}_REQUEST`:
        //  return { ...state, meta: action.payload };
        case `${API_FUNDCLUSTER_LIST_ACTION}_SUCCESS`:
            return {
                ...state,
                data: action.payload.data,
                meta: {
                    ...state.meta,
                    total: action.payload.total,
                    page: action.payload.page,
                    take: action.payload.take,
                    q: action.payload.q,
                }
            };
        case `${API_FUNDCLUSTER_CREATE_ACTION}_SUCCESS`:
            newData = state.data;
            newData.unshift(action.payload.fundcluster);
            return {
                ...state,
                data: newData
            };
        case `${API_FUNDCLUSTER_UPDATE_ACTION}_SUCCESS`:
            newData = state.data;
            var response = action.payload.fundcluster;
            for (var i = 0; i < newData.length; i++) {
                if (newData[i].id === response.id) {
                    newData[i] = response;
                }
            }
            return {
                ...state,
                data: newData
            };
        default:
            return state;
    }
}