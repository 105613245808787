import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';

import { HashRouter, Route, Switch } from 'react-router-dom';
import Pap from './_pap';
import Bur from './_bur';
import _ from 'lodash';
const { Content } = Layout;


const MENU_ITEMS = [
    { title: 'Financial Plan', value: 'budget', permission: 'fmd_view_pap' },
    { title: 'Budget Utilization Request', value: 'budget/bur', permission: 'fmd_view_bur' }
]

class Budget extends Component {

    getMenu = () => {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <Menu
                mode="horizontal"
                style={{ fontSize: 18, color: '#9AA0A5' }}
                onClick={this.onClick_Menu}
                defaultSelectedKeys={["Financial Plan"]}
            >

                {
                    MENU_ITEMS.map((item) => {
                        if (item.permission && !permissions.includes(item.permission)) {
                            return null;
                        }
                        return (
                            <Menu.Item key={item.title} >
                                <NavLink to={`/${item.value}`} style={{ fontWeight: '200', color: 'black' }}>
                                    {item.title}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
    }

    render() {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <div>
                <Layout className="layout">
                    {this.getMenu()}

                    <Content style={{ background: "white", paddingTop: '30px' }} >
                        <HashRouter>
                            <Switch>
                                {
                                    permissions.includes('fmd_view_pap') && <Route path="/budget" exact component={Pap} />
                                }
                                {
                                    permissions.includes('fmd_view_bur') && <Route path="/budget/bur" exact component={Bur} />
                                }

                            </Switch>
                        </HashRouter>
                    </Content>
                </Layout>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loggedInUser: state.loggedInUser,
    })
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
