import React, { Component } from 'react';
import { Radio } from 'antd';

const mode_of_payment_options = [
    { label: 'Cash', value: 'CASH' },
    { label: 'Check', value: 'CHECK' },
    { label: 'Bank Deposit', value: 'DEPOSIT' },
];



class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            value: value
        };
    }

    componentDidUpdate(prevProps) {
    }

    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || {}
            });
        }
    }

    onChange = (e) => {
        this.setState({
            value: {...this.state.value, mode_of_payment: e.target.value}
        });
        this.props.onChange({...this.state.value, mode_of_payment: e.target.value})
    }

    render() {
        return <div>
                <Radio.Group onChange={this.onChange} value={this.state.value.mode_of_payment}>
                    {
                        mode_of_payment_options.map((item) => {
                            return <Radio value={item.value} key={item.value}>{item.label}</Radio>
                        })
                    }    
                </Radio.Group>

        </div>
    }
}

export default MyComponent;
