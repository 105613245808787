import React, { Component } from 'react';
import { Divider, Form, Input, Col, Row } from 'antd';
import BankSelect from 'modules/accounting/components/BankSelect';
import accounting from 'accounting'
import { Drawer, Button, notification } from 'antd';
import shared_styles from 'shared/constants/styles';

const FormItem = Form.Item;

class CreateUpdateFormBase extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleSubmit(values);
            }
        });
    }

    render() {
        const { form, data } = this.props;
        const { getFieldDecorator } = form;
        const type = data.type;
        return (
            <Form id='orderofpaymentForm' onSubmit={this.handleSubmit}>
                <FormItem
                    label='Estimate Number'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ margin: '0px' }}
                >
                    <Input disabled value={data.estimate_number} />
                </FormItem>
                <Form.Item label="Bank Account">
                    {
                        getFieldDecorator('bank_accounts', {
                            rules: [{ required: false, message: 'Please input!' }],
                            initialValue: null
                        })(
                            <BankSelect mode='multiple' labelInValue={false} />
                        )
                    }
                </Form.Item>
                <Divider />
                <FormItem
                    label='Charged to'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ margin: '0px' }}
                >
                    <Input disabled value={data.op_data.charged_to} />
                </FormItem>
                <Divider />
                <FormItem
                    label='Order of Payment Date'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input disabled value={data.op_date} />
                </FormItem>
                <Row gutter={24}>
                    {
                        type !== 'Publication' &&
                        <Col xs={24} sm={4}>
                            <FormItem
                                label='Qty'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input disabled value={data.op_data.op_quantity} />
                            </FormItem>
                        </Col>
                    }
                    {
                        type !== 'Publication' &&
                        <Col xs={24} sm={6}>
                            <FormItem
                                label='UNIT'
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input disabled value={data.op_data.op_unit} />
                            </FormItem>
                        </Col>
                    }
                    <Col xs={24} sm={14}>
                        <FormItem
                            label='PARTICULARS'
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input disabled value={data.op_particulars} />
                        </FormItem>
                    </Col>
                </Row>
                <FormItem
                    label='Amount Due'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input disabled value={accounting.formatMoney(data.op_amount, '₱', 2, ',', '.')} />
                </FormItem>
            </Form>
        );
    }
}

const CreateUpdateForm = Form.create()(CreateUpdateFormBase);

export default class CreateUpdate extends Component {
    handleSubmit = () => {
        this.formRef.props.form.validateFields((err, values) => {
            console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;

                if (handleSubmit) {
                    //console.log(values);
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });

    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }


    render() {
        // console.log(this.props.data);
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                {
                    this.props.data && <CreateUpdateForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        handleSubmit={this.handleSubmit}
                        data={this.props.data}
                    />
                }
                <div style={shared_styles.drawer_top}>
                    Finalize
                </div>


                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

