import React, { Component } from 'react';
import { Input, Switch, Drawer, Form, Button, Col, Row, Select, notification } from 'antd';
import shared_styles from 'shared/constants/styles';
import _ from 'lodash';
import { PAP_CODE_OPTIONS, PAP_END_USER_OPTIONS } from 'shared/constants/definitions';
const Option = Select.Option;


const WrappedForm = Form.create()(
    class extends Component {

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            var permissions = _.get(this.props.user, 'permissions.accounting', []);
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item >
                                <label style={{ marginRight: 15 }}>Status</label>
                                {
                                    getFieldDecorator('is_active', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: true,
                                        valuePropName: 'checked'
                                    })(
                                        <Switch disabled={!permissions.includes('fmd_deactivate_pap')} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Year">
                                {
                                    getFieldDecorator('pap_year', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Code">
                                {
                                    getFieldDecorator('pap_code', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <Select mode='multiple'>
                                            {
                                                PAP_CODE_OPTIONS.map(function (item) {
                                                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>



                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Procurement Program/Project">
                                {
                                    getFieldDecorator('pap_program', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Mode of Procurement">
                                {
                                    getFieldDecorator('pap_mode_of_procurement', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="End User">
                                {
                                    getFieldDecorator('pap_end_users', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: [],
                                    })(
                                        <Select mode='multiple'>
                                            {
                                                PAP_END_USER_OPTIONS.map(function (item) {
                                                    return <Option value={item.value} key={item.value}>{item.text}</Option>
                                                })
                                            }
                                        </Select>

                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Estimated Budget">
                                {
                                    getFieldDecorator('pap_estimated_budget', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.pap && prevProps.pap !== this.props.pap) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.pap
                })
            }
        }
    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;

                if (handleSubmit) {
                    //console.log(values);
                    values = {
                        ...values
                    }
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        // console.log(this.props.pap)
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    {this.props.pap ? 'Update Financial Plan' : 'Create Financial Plan'}
                </div>
                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        user={this.props.user}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
