import React from 'react';

const IsActive = ({ value }) => {

    return value === true || value === 1
        ?
        <div style={{ display: 'flex', flexDirection: 'row' }}  >
            <div style={{ backgroundColor: '#6FCF97', height: 10, width: 10, borderRadius: 50, alignSelf: 'center' }} />
            <span style={{ margin: '0px 5px', fontWeight: 'bold' }}> Active </span>
        </div>
        :
        <div style={{ display: 'flex', flexDirection: 'row' }} >
            <div style={{ backgroundColor: 'red', height: 10, width: 10, borderRadius: 50, alignSelf: 'center' }} />
            <span style={{ margin: '0px 5px', fontWeight: 'bold' }}> Inactive </span>
        </div>
}

export default IsActive