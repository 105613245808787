import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_ACCOUNT_CREATE_ACTION = 'API_ACCOUNT_CREATE_ACTION';
export const API_ACCOUNT_READ_ACTION = 'API_ACCOUNT_READ_ACTION';
export const API_ACCOUNT_UPDATE_ACTION = 'API_ACCOUNT_UPDATE_ACTION';
export const API_ACCOUNT_LIST_ACTION = 'API_ACCOUNT_LIST_ACTION';
export const API_ACCOUNT_LIST_ALL_ACTION = 'API_ACCOUNT_LIST_ALL_ACTION';

export const account_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_ACCOUNT_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.account_create(body),
            API_ACCOUNT_CREATE_ACTION,
            callback
        );
    }
}

export const account_read = (account_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_ACCOUNT_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.account_read(account_id),
            API_ACCOUNT_READ_ACTION,
            callback
        );
    }
}

export const account_update = (account_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_ACCOUNT_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.account_update(account_id, body),
            API_ACCOUNT_UPDATE_ACTION,
            callback
        );
    }
}

export const account_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_ACCOUNT_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.account_list(meta),
            API_ACCOUNT_LIST_ACTION,
            callback
        );
    }
}

export const account_list_all = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_ACCOUNT_LIST_ALL_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.account_list_all(meta),
            API_ACCOUNT_LIST_ALL_ACTION,
            callback
        );
    }
}
