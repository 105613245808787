import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'qs';

import { login, API_LOGIN_TYPE } from '../actionCreators';
import { OAUTH_URL } from 'shared/constants/urls';

class Login extends Component {
    componentDidMount() {
        if (this.props.loggedInUser != null) {
            this.props.history.push('/');
        }
        else {
            const query = queryString.parse(this.props.location.search);
            if (query.code) {
                this.props.login({ code: query.code });
            }
            else if (query['?code']) {
                this.props.login({ code: query['?code'] });
            }
            else {
                window.location.href = OAUTH_URL;
            }
        }
    }

    componentDidUpdate() {
        if (this.props.loggedInUser != null) {
            this.props.history.push('/');
        }
    }


    render() {
        // const errorMessage = 
        // <div>
        //     { this.props.loginError? <Alert message={this.props.loginError.message} type="error" />: '' }
        //     <br />
        // </div>

        return (
            <div className="row">

            </div>

        );
    }
}

const mapStateToProps = state => ({
    loginLoading: state.loadingReducer[API_LOGIN_TYPE],
    loginError: state.errorReducer[API_LOGIN_TYPE],
    loggedInUser: state.loggedInUser,
})

const mapDispatchToProps = {
    login
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
