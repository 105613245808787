import { API_OP_CREATE_ACTION, API_OP_UPDATE_ACTION, API_OP_LIST_ACTION, API_OP_FINALIZE_ACTION } from '../actions/op_actions'

export const opListReducer = (state = { data: [] }, action) => {
    var newData;
    switch (action.type) {
        //case `${API_CASE_LIST_ACTION}_REQUEST`:
        //  return { ...state, meta: action.payload };
        case `${API_OP_LIST_ACTION}_SUCCESS`: {
            //console.log(action.payload.data);
            return {
                ...state,
                data: action.payload.data,
                meta: {
                    ...state.meta,
                    total: action.payload.total,
                    page: action.payload.page,
                    take: action.payload.take,
                    q: action.payload.q,
                }
            };
        }
        case `${API_OP_FINALIZE_ACTION}_SUCCESS`: {
            newData = state.data;
            let response = action.payload.order_of_payment;
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === response.id) {
                    newData[i] = response;
                }
            }
            return {
                ...state,
                data: newData
            };
        }
        case `${API_OP_CREATE_ACTION}_SUCCESS`: {
            newData = state.data;
            newData.unshift(action.payload.order_of_payment);
            return {
                ...state,
                data: newData
            };
        }
        case `${API_OP_UPDATE_ACTION}_SUCCESS`: {
            newData = state.data;
            let response = action.payload.order_of_payment;
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === response.id) {
                    newData[i] = response;
                }
            }
            return {
                ...state,
                data: newData
            };
        }
        default:
            return state;
    }
}