import React from 'react';
import { Icon } from 'antd';

const Header = ({ title, titleComponent, children, containerStyle, titleStyle, loading }) => {
    return (
        <div style={Object.assign({}, styles.container, containerStyle ? containerStyle : {})}>
            {
                !!title && <h2 style={Object.assign({}, styles.title_text, titleStyle ? titleStyle : {})}>{title}</h2>
            }
            {
                !!titleComponent && <div style={styles.title_component}>{titleComponent}</div> // USAGE: <Header titleComponent={<SomeComponent {...props} />} />
            }
            <div style={styles.button_container}>
                {
                    loading
                    ? <Icon type="loading" />
                    : Array.isArray(children)
                    ? children.map((child, index) => !!child && <div key={index.toString()} style={styles.child}>{child}</div>)
                    : <div style={styles.child}>{children}</div>
                }
            </div>
        </div>
    )
};

export default Header;

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: "15px",
        paddingTop: "10px"
    },
    title_text: {
        marginBottom: '0px',
        fontSize: '1.5em',
        margin: '0px 5px'
    },
    title_component: {
        margin: '10px',
        minWidth: '80px',
    },
    button_container: {
        display: 'flex',
    },
    child: {
        margin: '10px',
        minWidth: '80px',
    },
};
