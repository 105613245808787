import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChartOfAccountTable from './components/ChartOfAccountTable';
import FormChartOfAccount from './components/form_chartOfAccount';
import { account_list, account_create, account_update } from '../actions/account_actions'
import { notification, Modal } from 'antd';
import { get } from 'lodash';
import FormJournal from 'modules/journal/components/form_Journal';
import { journal_create } from 'modules/journal/actions/journal_actions';
import API_CLIENT from 'shared/utils/api_client';

const confirm = Modal.confirm;

class ChartOfAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = { form_visible: false, currentEdit: null, form_initialize_visible: false, canInitialize: true };
    }

    initialize_showForm = () => {
        this.setState({ form_initialize_visible: true });
    };

    initialize_hideForm = () => {
        this.setState({ form_initialize_visible: false });
        this.initialize_form.reset();
    };

    initialize_onSubmit = (values) => {
        console.log('initialize_onSubmit', values);
        confirm({
            title: 'There\'s no turning back! Once initialized, you cannot initialize again. Are you sure you want to proceed?',
            description: '',
            onOk: () => {
                // TODO
                this.props.journal_create({ ...values, isInitialize: 1 }, this.initialize_callbackSubmit);
                API_CLIENT.config_update('initialization_date', {
                    value: {
                        date: values.journalbatch_date
                    }
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        notification['error']({ message: error.message });
                    });
            },
            okText: 'Yes, initialize trial balance!',
            cancelText: 'No, go back!'
        });

    }

    initialize_callbackSubmit = (data) => {
        if (data.journalbatch) {
            notification['success']({ message: 'Success!' });
            this.initialize_hideForm();
            this.setState({ canInitialize: false })
        }
        else {
            notification['error']({ message: data.message });
        }
    }

    showForm = () => {
        this.setState({ form_visible: true });
    };

    hideForm = () => {
        this.form.reset();
        this.setState({ form_visible: false, currentEdit: null });
    };

    componentDidMount() {
        this.props.account_list({ page: 1, take: 10, sort_key: 'account_uacs' });
        API_CLIENT.config_read('initialization_date', {})
            .then((response) => {
                if (response.data.id) this.setState({ canInitialize: false });
            })
            .catch((error) => {
                // notification['error']({ message: error.message });
            });
    }

    onSearch = (q) => {
        const filters = get(this.props, 'accounts.meta.filters', {});
        const meta = get(this.props, 'accounts.meta.filters', {});
        this.props.account_list({
            ...meta,
            ...filters,
            q: q,
        });
    }

    onSubmit = (values) => {
        console.log('onSubmit', values);
        if (this.state.currentEdit) {
            this.props.account_update(this.state.currentEdit.id, values, this.callbackSubmit);
            console.log(this.state.currentEdit.id);
        }
        else {
            this.props.account_create(values, this.callbackSubmit);
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const filter = get(this.props, 'accounts.meta.filters', {});
        const meta = get(this.props, 'accounts.meta.filters', {});
        this.props.account_list({
            ...meta,
            ...filter,
            take: pagination.pageSize,
            page: pagination.current,
            sort_key: sorter.columnKey,
            sort_dir: sorter.order,
            ...filters
        })
    }


    callbackSubmit = (data) => {
        if (data.account) {
            notification['success']({ message: 'Success!' });
            this.hideForm();
        }
        else {
            notification['error']({ message: data.message });
        }
    }

    onEdit = (record) => {
        const item = this.props.accounts.data.find(datum => datum.id === record.id)
        this.setState({ currentEdit: item, form_visible: true });
    }

    onDelete = (index) => {
        console.log('TODO: onDelete', this.props.accounts.data[index]);
    }

    render() {

        return (
            <div>
                <ChartOfAccountTable
                    accounts={this.props.accounts}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    onTableChange={this.handleTableChange}
                    showForm={this.showForm}
                    showInitialize={this.initialize_showForm}
                    canInitialize={this.state.canInitialize}
                    onSearch={this.onSearch}
                    user={this.props.user}
                />
                <FormChartOfAccount
                    visible={this.state.form_visible}
                    onCancel={this.hideForm}
                    onSubmit={this.onSubmit}
                    ref={(ref) => { this.form = ref }}
                    account={this.state.currentEdit}
                    user={this.props.user}
                />
                <FormJournal
                    visible={this.state.form_initialize_visible}
                    onCancel={this.initialize_hideForm}
                    onSubmit={this.initialize_onSubmit}
                    ref={(ref) => { this.initialize_form = ref }}
                    isInitialize={true}
                />

            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        accounts: state.accountListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    account_list, account_create, account_update, journal_create
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
