import React, { Component } from 'react';
import { DatePicker, Form, Col, Row } from 'antd';
import PartnerSelect from 'shared/components/PartnerSelect';

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >
                                
                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            {
                                <Form.Item >                                
                                {
                                    getFieldDecorator('partner_id', {
                                        rules: [{ required: true, message: 'Partner' }]
                                    })(
                                        <PartnerSelect />
                                    )
                                }
                                </Form.Item>
                            }
                        </Col>                    
                    </Row>
                    
                </Form>
            );
        }
    }
);

export default WrappedForm;