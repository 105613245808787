import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import _ from 'lodash';
import { login } from 'layouts/landing/actionCreators';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { notification, Dropdown, Button, Badge, Icon, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { getNotifications } from 'modules/notification/actionCreators';
import { notificationCount } from 'modules/notification/actionCreators';
import { getNotificationsCount } from 'modules/notification/actionCreators';

import Accounting from '../../modules/accounting/accounting';
import Budgets from '../../modules/budget/budget';
import Vouchers from '../../modules/voucher/voucher';
import Collections from '../../modules/collection/collection';
import Journals from '../../modules/journal/journal';
import Partners from '../../modules/partner/partner';
import Reports from '../../modules/reports/reports';
import '../../modules/accounting/components/style.css';
import Clock from 'react-live-clock';
import IdleTimer from 'react-idle-timer';
import { css } from 'emotion';




const { Header, Content, Footer, Sider } = Layout;

notification.config({
    top: 50,
    right: 0,
    duration: 1.5,
});

const sideBarMenuStyle = css({
    backgroundColor: 'transparent',
    '& .ant-menu-item:after': {
        borderRight: '0px',
        borderLeft: '3px solid #1890ff',
        left: 0,
    },
    '#sidemenu & .ant-menu-item-selected': {
        backgroundColor: 'transparent',
        fontWeight: '700',
    }
})

const MENU_ITEMS = [
    { title: 'Accounting', value: 'accounting', permission: ['fmd_view_fundcluster', 'fmd_view_bank', 'fmd_view_account', 'fmd_view_tax'] },
    { title: 'Budget', value: 'budget', permission: ['fmd_view_pap', 'fmd_view_bur'] },
    { title: 'Vouchers', value: 'voucher', permission: ['fmd_view_voucher'] },
    { title: 'Collections', value: 'collection', permission: ['fmd_view_receipt', 'fmd_view_order_of_payment'] },
    { title: 'Journal Entries', value: 'journal', permission: ['fmd_view_journal'] },
    { title: 'Partner Management', value: 'partner', permission: ['fmd_view_agency'] },
    {
        title: 'Reports', value: 'report', permission: ['fmd_print_general_journal',
            'fmd_print_ckdj',
            'fmd_print_cdj',
            'fmd_print_crj',
            'fmd_print_general_ledger',
            'fmd_print_subsidiary_ledger',
            'fmd_print_index_of_payment',
            'fmd_print_trial_balance',
            'fmd_print_financial_statements']
    },

]

class MainLayout extends Component {


    componentDidUpdate(prevProps) {
        if (this.props.loggedInUser == null) {
            this.props.history.push('/login');
        }
        const lastNotificationRetrieved = get(
            this.props,
            "notifications.meta.dateTimeRetrieved"
        );
        const notificationsLoading = get(this.props, "notifications.loading");
        if (
            !notificationsLoading &&
            (!lastNotificationRetrieved ||
                moment().diff(lastNotificationRetrieved, "minute") > 15)
        ) {
            this.props.getNotifications();
        }
    }
    componentDidMount() {
        if (this.props.loggedInUser == null) {
            this.props.history.push('/login');
        } else {
            const token = sessionStorage.getItem('token');
            if (!token) {
                sessionStorage.setItem('token', this.props.loggedInUser.token);
            }
            this.props.getNotifications();
        }

    }
    topMenuItems = (
        <Menu>
            <Menu.Item key="2"><NavLink to='/logout'>Logout</NavLink></Menu.Item>
        </Menu>
    )

    onClick_Menu = ({ item, key, keyPath }) => {
        console.log(key);
        this.setState({ title: key });
    }

    getMenu = () => {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <Menu
                mode="inline" theme="light"
                style={{ borderRight: '0px', backgroundColor: '#F5F6F7' }}
                onClick={this.onClick_Menu}
                defaultSelectedKeys={['Accounting']}
                className={sideBarMenuStyle}
            >
                {
                    MENU_ITEMS.map((item) => {

                        if (item.permission) {
                            let permission_intersection = permissions.filter(value => item.permission.includes(value));
                            if (permission_intersection.length === 0) {
                                return null;
                            }
                        }
                        return (
                            <Menu.Item key={item.title}  >
                                <NavLink style={{ margin: '1px 0px 37px 0px', fontWeight: "20px" }} to={`/${item.value}`}>
                                    {item.title}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
    }

    onNotificationClick = (item, key, keyPath) => {
        const type = get(item, "item.props.data.type", "");
        const id = get(item, "item.props.data.object_id", -1);

        console.log('clicked', type)
        switch (type) {
            case "accounting":
                this.props.history.push(`/accounting/${id}`);
                break;
            case "budget":
                this.props.history.push(`/budget/${id}`);
                break;
            case "voucher":
                this.props.history.push(`/voucher/${id}`);
                break;
            case "collection":
                this.props.history.push(`/collection/${id}`);
                break;
            case "journal":
                this.props.history.push(`/journal/${id}`);
                break;
            case "partner":
                this.props.history.push(`/partner/${id}`);
                break;
            case "report":
                this.props.history.push(`/report/${id}`);
                break;
            default:
                break;
        }
    };


    getNotifications = () => {
        const { notifications } = this.props;
        const { data, loading } = notifications;

        if (loading) {
            return (
                <Menu>
                    <Menu.Item disabled>
                        <div>Notifications</div>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <Spin />
                    </Menu.Item>
                </Menu>
            );
        }

        if (data.length <= 0) {
            return (
                <Menu onClick={this.onNotificationClick}>
                    <Menu.Item disabled>
                        <div>Notifications</div>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <div>No New Notifications</div>
                    </Menu.Item>
                </Menu>
            );
        }

        return (

            <Menu onClick={this.onNotificationClick}>
                <Menu.Item disabled>
                    <div>Notifications</div>
                </Menu.Item>
                <Menu.Divider />
                {data.map((notification, index) => {
                    return (

                        <Menu.Item key={index.toString()} data={notification}>

                            <div>{notification.description}</div>
                        </Menu.Item>
                    );
                })}

            </Menu>
        );
    };


    render() {

        if (this.props.loggedInUser == null) {
            return <div>Logged out.</div>
        }
        const topMenu = (
            <div>
                <div style={{ float: 'left' }}>
                    <h1 style={{ fontSize: '42px', paddingLeft: '20px', margin: '0px 0px 60px 20px', color: '#2B3D44', fontWeight: '300', paddingTop: '15px' }}>
                        <HashRouter>
                            <Switch>
                                <Route path="/:path" render={(props) => { return <span>{_.keyBy(MENU_ITEMS, 'value')[props.match.params.path].title}</span> }} />
                                <Route path="/" render={(props) => { return <span>Accounting</span> }} />
                            </Switch>
                        </HashRouter>
                    </h1>
                </div>
                <div style={{ float: 'right', border: '0px', marginRight: '10px', paddingTop: '10px' }}>
                    <Clock
                        format={'h:mm:ss a'}
                        ticking={true}
                        timezone={'Asia/Manila'}
                        style={{
                            paddingRight: '13px'
                        }}
                    />
                    <Dropdown
                        overlay={this.getNotifications()}
                        trigger={['click']}
                    >
                        <Badge count={this.props.notifications.count} style={{ fontSize: '10px', padding: '0' }}>
                            <button onClick={this.props.getNotificationsCount} style={{ border: 'none' }}>
                                <Icon type="bell" style={{ fontSize: '20px' }} />
                            </button>
                        </Badge>
                    </Dropdown>
                    <Dropdown overlay={this.topMenuItems}>
                        <Button style={{ border: '0px', boxShadow: 'none' }}>
                            {this.props.loggedInUser.user.name} <Icon type="down" />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        );
        return (
            <div>
                <IdleTimer
                    timeout={900000}
                    onIdle={() => window.location = "/#/logout"}
                >
                    {
                        this.props.isLoading && <div id="cover-spin"></div>
                    }
                    <Layout style={{ minHeight: '100vh' }}>
                        <Sider width='300' style={{ backgroundColor: '#F5F6F7' }}>
                            <div style={{ margin: '20px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img alt="" height="80px" width="80px" src="/favicon.ico" />
                                <span style={{ fontWeight: '600', color: '#242526', fontSize: '18px', margin: '5px 0px' }}>Company</span>
                                <span style={{ color: '#646970', fontSize: '16px' }}>Finance Management Division</span>
                            </div>

                            <div id="sidemenu">
                                {this.getMenu()}
                            </div>
                        </Sider>
                        <Layout style={{ backgroundColor: '#FFFFFF' }}>
                            <Header style={{ backgroundColor: '#FFFFFF', padding: '10px 20px' }}>
                                {topMenu}
                            </Header>
                            <Content style={{ margin: '20px 60px 20px 60px' }}>
                                <HashRouter>
                                    <Switch>
                                        <Route path="/accounting" to="/accounting" component={Accounting} />
                                        <Route path="/budget" to="/budget" component={Budgets} />
                                        <Route path="/voucher" to="/voucher" component={Vouchers} />
                                        <Route path="/collection" to="/collection" component={Collections} />
                                        <Route path="/journal" to="/journal" component={Journals} />
                                        <Route path="/partner" to="/partner" component={Partners} />
                                        <Route path="/report" to="/report" component={Reports} />
                                        <Route path="/" to="/accounting" component={Accounting} />
                                    </Switch>
                                </HashRouter>
                            </Content>
                            <Footer style={{ textAlign: 'center', background: "white" }}></Footer>
                        </Layout>
                    </Layout>
                </IdleTimer>
            </div>

        );

    }
}

const mapStateToProps = state => {
    console.log(state)
    return {
        loggedInUser: state.loggedInUser,
        isLoading: _.some(state.loadingReducer, function (item) {
            return item === true;
        }),
        notifications: state.notification

    }
}

const mapDispatchToProps = {
    login,
    getNotifications,
    notificationCount,
    getNotificationsCount
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
