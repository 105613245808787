import React, { Component } from 'react';
import { connect } from 'react-redux';
import PapTable from './components/PapTable';
import FormPap from './components/form_Pap';
import { pap_list, pap_create, pap_update } from '../actions/pap_actions'
import { notification } from 'antd';
import moment from 'moment';

class Pap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_visible: false,
            currentEdit: null,
            q: '',
            q_year: moment().format('YYYY')
        };
    }

    showForm = () => {
        this.setState({ form_visible: true });
    };

    hideForm = () => {
        this.form.reset();
        this.setState({ form_visible: false, currentEdit: null });
    };

    componentDidMount() {
        this.props.pap_list({ page: 1, take: 10, sort_key: 'id', sort_dir: 'descend', pap_year: [this.state.q_year] });
    }

    onSearch = (q) => {
        this.setState({ q: q }, () => {
            this.props.pap_list({
                ...this.props.paps.meta,
                ...this.props.paps.meta.filters,
                q: this.state.q,
                pap_year: [this.state.q_year]
            });
        });
    }

    onChangeYear = (e) => {
        const q_year = e.target.value;
        console.log(this.props.paps);
        this.setState({ q_year: q_year }, () => {
            if (parseInt(q_year) > 2000 && parseInt(q_year) < 30000) {
                this.props.pap_list({
                    ...this.props.paps.meta,
                    ...this.props.paps.meta.filters,
                    q: this.state.q,
                    pap_year: [q_year]
                });
            }
        });
    }

    onSubmit = (values) => {
        console.log('onSubmit', values);
        if (this.state.currentEdit) {
            this.props.pap_update(this.state.currentEdit.id, values, this.callbackSubmit);
        }
        else {
            this.props.pap_create(values, this.callbackSubmit);
        }
    }

    callbackSubmit = (data) => {
        if (data.pap) {
            notification['success']({ message: 'Success!' });
            this.hideForm();
        }
        else {
            notification['error']({ message: data.message });
        }
    }

    onEdit = (record) => {
        const item = this.props.paps.data.find(datum => datum.id === record.id)
        this.setState({ currentEdit: item, form_visible: true });
    }

    onDelete = (index) => {
        console.log('TODO: onDelete', this.props.paps.data[index]);
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.pap_list({
            ...this.props.paps.meta,
            ...this.props.paps.meta.filters,
            take: pagination.pageSize,
            page: pagination.current,
            sort_key: sorter.columnKey,
            sort_dir: sorter.order,
            ...filters,
            pap_year: [this.state.q_year]
        })
    }

    render() {
        // console.log("TODO: EDIT", this.props.papList[2] )
        return (
            <div>
                <PapTable
                    paps={this.props.paps}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    onTableChange={this.handleTableChange}
                    showForm={this.showForm}
                    q_year={this.state.q_year}
                    onChangeYear={this.onChangeYear}
                    onSearch={this.onSearch}
                    user={this.props.user}
                />
                <FormPap
                    visible={this.state.form_visible}
                    onCancel={this.hideForm}
                    onSubmit={this.onSubmit}
                    ref={(ref) => { this.form = ref }}
                    pap={this.state.currentEdit}
                    user={this.props.user}
                />
            </div>

        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        paps: state.papListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    pap_list, pap_create, pap_update
}

export default connect(mapStateToProps, mapDispatchToProps)(Pap);
