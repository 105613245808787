import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Switch, Input, Popover, Checkbox, Icon, notification } from 'antd';
import { get } from 'lodash';
import CreateUpdate from './CreateUpdate';
import Header from 'shared/components/Header';
import { getPartners, togglePartnerStatus } from '../actionCreators';

const { Search } = Input;

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateDrawer: false,
            data: '',
            columnsToShow: [
                'partner_name',
                'partner_type',
                'client_type',
                'region',
                'province',
                'municipality',
                'contact_person_name',
                'contact_person_number',
                'is_active'
            ]
        }
    }

    columns = [{
        title: 'Partner Name',
        dataIndex: 'partner_name',
        key: 'partner_name',
        sorter: true
    }, {
        title: 'Partner Type',
        dataIndex: 'partner_type',
        key: 'partner_type',
        filters: [
            { text: 'Supplier', value: 'Supplier' },
            { text: 'Client', value: 'Client' }
        ],
    }, {
        title: 'Client Type',
        dataIndex: 'client_type',
        key: 'client_type',
    }, {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
    }, {
        title: 'Province',
        dataIndex: 'province',
        key: 'province',
    }, {
        title: 'Municipality',
        dataIndex: 'municipality',
        key: 'municipality',
    }, {
        title: 'Contact Person',
        dataIndex: 'contact_person.name',
        key: 'contact_person_name',
        render: (item, record) => { return (!!record ? record.contact_persons[0].name : ''); }
    }, {
        title: 'Contact Number',
        dataIndex: 'contact_person.contact_number',
        key: 'contact_person_number',
        render: (item, record) => !!record ? record.contact_persons[0].contact_number : ''
    }, {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (value, row) => {
            return <Switch size="small" checked={value} onChange={(checked) => this.toggleStatus(row.id, checked)} />
        },
        filters: [
            { "text": "Inactive", value: 0 },
            { "text": "Active", value: 1 }
        ]
    }, {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, partner) => {
            return (
                <div>
                    <Button
                        style={{ border: 'none', background: 'none' }}
                        icon="edit"
                        onClick={(e) => this.onEditClicked(e, partner)}
                        className='editDeleteButtons'
                    />
                </div>
            )
        },
    }
    ];

    componentDidMount() {
        if (this.disableView()) {
            notification.warning({
                message: 'Uh Oh!',
                description: 'You do not have permission to view this module.',
            });
            return;
        }
        this.props.getPartners({
            take: 20,
            page: 1,
        });
    }

    toggleStatus = (id, checked) => {
        if (this.disableEdit()) {
            notification.warning({
                message: 'Uh Oh!',
                description: 'You do not have permission to activate/deactivate partners.',
            });
            return;
        }
        this.props.togglePartnerStatus(id, checked ? 'enable' : 'disable')
    }

    onNewClicked = () => {
        this.setState({ showCreateDrawer: true, data: '' })
    }

    onDrawerCloseClicked = () => {
        this.setState({ showCreateDrawer: false, data: '' })
    }

    onEditClicked = (e, partner) => {
        e.stopPropagation();
        if (this.disableEdit()) {
            notification.warning({
                message: 'Uh Oh!',
                description: 'You do not have permission to edit partners.',
            });
            return;
        }
        this.setState({ showCreateDrawer: true, data: partner })
    }

    onTableChange = (pagination, filters, sorter) => {
        if (this.disableView()) {
            notification.warning({
                message: 'Uh Oh!',
                description: 'You do not have permission to view this module.',
            });
            return;
        }

        this.props.getPartners({
            ...this.props.meta,
            page: pagination.current,
            take: pagination.pageSize,
            ...filters,
            sort_dir: sorter.order,
            sort_key: sorter.columnKey
        });
    }

    onSearch = (value) => {
        this.props.getPartners({
            ...this.props.meta,
            q: value
        });
    }

    filteredColumns = () => {
        return this.columns.filter(column => column.key === 'actions' || this.state.columnsToShow.includes(column.key))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.columnsToShow];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ columnsToShow: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.columns.filter(column => column.key !== 'actions').map(column => {
                        const checked = this.state.columnsToShow.includes(column.key);
                        return (
                            <div key={column.key}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.key)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    disableAdd = () => {
        const permissions = get(this.props, 'user.permissions.accounting');
        if (!Array.isArray(permissions)) {
            return true;
        }
        return !permissions.includes('fmd_add_agency');
    }

    disableView = () => {
        const permissions = get(this.props, 'user.permissions.accounting');
        if (!Array.isArray(permissions)) {
            return true;
        }
        return !permissions.includes('fmd_view_agency');
    }

    disableEdit = () => {
        const permissions = get(this.props, 'user.permissions.accounting');
        if (!Array.isArray(permissions)) {
            return true;
        }
        return !permissions.includes('fmd_edit_agency');
    }

    disableToggle = () => {
        const permissions = get(this.props, 'user.permissions.accounting');
        if (!Array.isArray(permissions)) {
            return true;
        }
        return !permissions.includes('fmd_deactivate_agency');
    }


    render() {
        const { loading } = this.props;
        const filteredColumns = this.filteredColumns();
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
                <Header
                    loading={loading}
                    titleComponent={
                        <Search
                            placeholder='Search partners'
                            onSearch={this.onSearch}
                            style={{ width: 400, marginLeft: 0 }}
                            disabled={this.disableView()}
                        />
                    }
                >
                    <Popover
                        title='Configure Columns'
                        content={this.columnsPopOver()}
                        trigger='click'
                    >
                        <Button
                            shape='circle'
                            size='large'
                            icon='setting'
                        />
                    </Popover>
                    <Button
                        type='primary'
                        onClick={this.onNewClicked}
                        disabled={this.disableAdd()}
                    >
                        <Icon type="plus" />New Partner
          </Button>
                </Header>
                <Table
                    columns={filteredColumns}
                    dataSource={this.props.data}
                    loading={this.props.loading}
                    rowKey='id'
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        position: 'bottom',
                        total: get(this.props, 'meta.total', 0)
                    }}
                    size="middle"
                    onChange={this.onTableChange}
                />
                <CreateUpdate
                    visible={this.state.showCreateDrawer}
                    onDrawerCloseClicked={this.onDrawerCloseClicked}
                    data={this.state.data}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ loggedInUser, partner }) => {
    return {
        ...partner,
        user: loggedInUser.user
    };
}

const mapDispatchToProps = {
    getPartners, togglePartnerStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
