import { axios } from 'shared/utils/api_client';
import { getError } from 'shared/utils/api_utils';
import {
	API_NOTIFICATION,
	API_NOTIFICATIONS,
	API_NOTIFICATIONSCLEAR
} from 'shared/constants/urls';
import { getAuthHeader } from 'shared/utils/api_client';
import moment from 'moment';

export const TYPES = {
	REQUEST: 'REQUEST_NOTIFICATIONS',
	GET_LIST: 'GET_NOTIFICATIONS',
	GET: 'GET_NOTIFICATION',
	ERROR: 'ERROR_NOTIFICATION',
	COUNT: 'NOTIFICATION_COUNTER'
}

export const getNotifications = (params) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });

		axios.get(API_NOTIFICATIONS, {
			headers: { ...getAuthHeader() },
			params: { ...params }
		})
		.then(response => {
			dispatch({ type: TYPES.GET_LIST, payload: { ...response.data, dateTimeRetrieved: moment() } })
			dispatch({ type: TYPES.COUNT, payload:response.data.data.length})
		})
		.catch(error => {
			dispatch({ type: TYPES.ERROR, payload: getError(error)})
		})
	}
}

export const getNotificationsCount = () => {
	return dispatch => {

		axios.post(API_NOTIFICATIONSCLEAR, {}, {
			headers: { ...getAuthHeader() },
		})
		.then(response => {
			dispatch({ type: TYPES.COUNT, payload:0})
			
		})
		
	}
}

export const getEstimate = (id, successCallback, errorCallback) => {
	return dispatch => {
		dispatch({ type: TYPES.REQUEST });
		
		axios.get(`${API_NOTIFICATION}/${id}`, {
			headers: { ...getAuthHeader() }
		})
		.then(response => { 
			if (successCallback) {
				successCallback(response.data);
			} 
			dispatch({ type: TYPES.GET, payload: response.data})
		})
		.catch(error => { 
			if (errorCallback) {
				errorCallback(getError(error));
			} 
			dispatch({ type: TYPES.ERROR, payload: getError(error)}) 
		})
	}
}

export const notificationCount = (count) => {
	console.log('reset')
    return  {
		type: TYPES.COUNT, 
		payload: count
    }
}

