import React, { Component } from 'react';
import { DatePicker, Form, Col, Row, Select } from 'antd';

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            var showStart = false;
            var showEnd = false;
            if (form.getFieldsValue().type === 'FINANCIAL_POSITION' || form.getFieldsValue().type === 'FINANCIAL_POSITION_DETAILED') {
                showEnd = true;
            }
            else if (form.getFieldsValue().type === 'FINANCIAL_PERFORMANCE' || form.getFieldsValue().type === 'FINANCIAL_PERFORMANCE_DETAILED') {
                showEnd = true;
                showStart = true;
            }

            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item >
                                {
                                    getFieldDecorator('type', {
                                        rules: [{ required: true, message: 'Type' }]
                                    })(
                                        <Select placeholder="Select Type">
                                            <Select.Option value="FINANCIAL_POSITION">Financial Position</Select.Option>
                                            <Select.Option value="FINANCIAL_POSITION_DETAILED">Financial Position (Detailed)</Select.Option>
                                            <Select.Option value="FINANCIAL_PERFORMANCE">Financial Performance</Select.Option>
                                            <Select.Option value="FINANCIAL_PERFORMANCE_DETAILED">Financial Performance (Detailed)</Select.Option>
                                            {
                                                // <Select.Option value="CASH_FLOW">Cash Flow</Select.Option>
                                                // <Select.Option value="CASH_FLOW_DETAILED">Cash Flow (Detailed)</Select.Option>
                                                // <Select.Option value="NET_ASSETS_EQUITY">Net Assets/Equity</Select.Option>
                                                // <Select.Option value="COMPARISON_OF_BUDGET">Comparison of Budget</Select.Option>

                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {
                            showStart &&
                            <Col span={8}>
                                <Form.Item >
                                    {
                                        getFieldDecorator('start_date', {
                                            rules: [{ required: showStart, message: 'Start Date' }]
                                        })(
                                            <DatePicker placeholder="Start Date" />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        }
                        {
                            showEnd &&
                            <Col span={8}>
                                <Form.Item >
                                    {
                                        getFieldDecorator('end_date', {
                                            rules: [{ required: showEnd, message: 'End Date' }]
                                        })(
                                            <DatePicker placeholder="End Date" />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                        }
                    </Row>

                </Form>
            );
        }
    }
);

export default WrappedForm;