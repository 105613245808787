import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';
const Option = Select.Option;

export default class MyComponent extends Component {

  constructor(props) {
    super(props);    
    const value = props.value || undefined;
    this.state = {
      value: value,
      data: [],
      fetching: false
    };
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 500);
  }

  // // for handling resetFields
  componentWillReceiveProps(props) {
    if ('value' in props) {
      this.setState({
          value: props.value || undefined,
      });
    }
  }

  componentDidMount = () => {
    this.fetchUser('');
  }

  clear = () => {
    this.setState({value: undefined});
  }

  fetchUser = (value) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    API_CLIENT.account_list({ take: 10000, q: value, is_active: [1], sort_key: 'account_uacs' })
      .then((response) => {
        if (fetchId !== this.lastFetchId) { // for fetch callback order
          return;
        }
        this.setState({data: response.data.data, fetching: false});
      })
      .catch((error) => {
        //console.log(error);
        notification['error']({ message: error.message });
      });
  }

  handleChange = (value) => {
    this.setState({value: value});
    //console.log(value);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    var { fetching, data, value } = this.state;
    if (data.length === 0) {
        value = null;
    }
    //console.log(data);
    //console.log(value);
    return (
      <Select
        //showSearch={true}
        mode={this.props.mode}
        value={value}
        placeholder="Select Account"
        notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
        filterOption={false}
        //onSearch={this.fetchUser}
        onChange={this.handleChange}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={false}
      >
        {data.map(d => 
            <Option key={d.id} value={d.id}>
                {this.props.show_detailed ? d.account_title + ' - ' + d.account_uacs : d.account_uacs }
            </Option>
        )}
      </Select>
    )

  }
}