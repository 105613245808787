import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_RECEIPT_CREATE_ACTION = 'API_RECEIPT_CREATE_ACTION';
export const API_RECEIPT_READ_ACTION = 'API_RECEIPT_READ_ACTION';
export const API_RECEIPT_UPDATE_ACTION = 'API_RECEIPT_UPDATE_ACTION';
export const API_RECEIPT_DELETE_ACTION = 'API_RECEIPT_DELETE_ACTION';
export const API_RECEIPT_LIST_ACTION = 'API_RECEIPT_LIST_ACTION';

export const receipt_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_RECEIPT_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.receipt_create(body),
            API_RECEIPT_CREATE_ACTION,
            callback
        );
    }
}

export const receipt_read = (receipt_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_RECEIPT_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.receipt_read(receipt_id),
            API_RECEIPT_READ_ACTION,
            callback
        );
    }
}

export const receipt_update = (receipt_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_RECEIPT_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.receipt_update(receipt_id, body),
            API_RECEIPT_UPDATE_ACTION,
            callback
        );
    }
}

export const receipt_delete = (receipt_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_RECEIPT_DELETE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.receipt_delete(receipt_id),
            API_RECEIPT_DELETE_ACTION,
            callback
        );
    }
}

export const receipt_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_RECEIPT_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.receipt_list(meta),
            API_RECEIPT_LIST_ACTION,
            callback
        );
    }
}
