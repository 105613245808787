import React, { Component } from 'react';
import { DatePicker, Form, Col, Row, Select } from 'antd';
import BankSelect from 'modules/accounting/components/BankSelect';
import TaxSelect from 'modules/accounting/components/TaxSelect';
import AccountSelect from 'modules/accounting/components/AccountSelect';
import PartnerSelect from 'shared/components/PartnerSelect';

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >    
                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item >                                
                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date"/>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item >                                
                                {
                                    getFieldDecorator('type', {
                                        rules: [{ required: true, message: 'Type' }]
                                    })(
                                        <Select placeholder="Select Type">
                                            <Select.Option value="BANK">Bank</Select.Option>
                                            <Select.Option value="TAX">Tax</Select.Option>
                                            <Select.Option value="PARTNER">Partner</Select.Option>
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>  
                        <Col span={16}>
                            {
                                form.getFieldsValue().type === 'BANK' &&
                                <Form.Item >                                
                                {
                                    getFieldDecorator('bank_account_id', {
                                        rules: [{ required: false, message: 'Bank' }]
                                    })(
                                        <BankSelect />
                                    )
                                }
                                </Form.Item>
                            }
                            {
                                form.getFieldsValue().type === 'TAX' &&
                                <Form.Item >                                
                                {
                                    getFieldDecorator('tax_account_id', {
                                        rules: [{ required: false, message: 'Tax' }]
                                    })(
                                        <TaxSelect />
                                    )
                                }
                                </Form.Item>
                            }
                            {
                                form.getFieldsValue().type === 'PARTNER' &&
                                <Form.Item >                                
                                {
                                    getFieldDecorator('partner_id', {
                                        rules: [{ required: false, message: 'Partner' }]
                                    })(
                                        <PartnerSelect />
                                    )
                                }
                                </Form.Item>
                            }                        
                        </Col>
                        <Col span={24}>
                            {
                                form.getFieldsValue().type === 'PARTNER' && 
                                <Form.Item >                                
                                {
                                    getFieldDecorator('account_id', {
                                        rules: [{ required: false, message: 'Account' }]
                                    })(
                                        <AccountSelect show_detailed={true}/>
                                    )
                                }
                                </Form.Item>
                            }
                        </Col>

                    </Row>
                    
                </Form>
            );
        }
    }
);

export default WrappedForm;