import React, { Component } from 'react';
import { Input, Drawer, Form, Button, Col, Row, DatePicker, notification, Radio } from 'antd';
import shared_styles from 'shared/constants/styles';
import InputModeOfPayment from '../../components/InputModeOfPayment';
import BankSelect from 'modules/accounting/components/BankSelect';
import PartnerSelect from 'shared/components/PartnerSelect'
import moment from 'moment';
import FilesUpload from 'shared/components/FilesUpload'
import API_CLIENT from 'shared/utils/api_client';
import OrderOfPayment from 'modules/collection/components/input_OrderOfPayment';
const WrappedForm = Form.create()(
    class extends Component {

        or_op_number_callback = (op) => {
            console.log(op);
            if (op) {
                this.props.form.setFieldsValue({
                    receipt_title: op.op_particulars,
                    receipt_partner_id: op.op_partner_id,
                    receipt_amount: op.op_amount
                });
            }
            else {
                this.props.form.setFieldsValue({
                    receipt_title: null,
                    receipt_partner_id: null,
                    receipt_amount: null
                });
            }

        }

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            console.log(this.props.form.getFieldValue('receipt_payment_details'));
            return (
                <Form layout='vertical'>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Official Receipt Number">
                                {
                                    getFieldDecorator('receipt_number', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Official Receipt Date">
                                {
                                    getFieldDecorator('receipt_date', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {
                            getFieldDecorator('manual_input', {
                                rules: [{ required: true, message: 'Please input!' }],
                                initialValue: 0,
                            })(
                                <Radio.Group style={{ 'display': 'none' }}>
                                    <Radio value={0}>Search OP</Radio>
                                    <Radio value={1}>Manual Input</Radio>
                                </Radio.Group>
                            )
                        }
                        <Col span={12}>
                            <Form.Item label="Order of Payment Number">
                                {
                                    this.props.form.getFieldValue('manual_input') === 0 &&
                                    getFieldDecorator('receipt_reference', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <OrderOfPayment onChange_withObject={this.or_op_number_callback} disabled={this.props.receipt} op_status={this.props.receipt ? 'PAID' : 'FINALIZED'} />
                                    )
                                }
                                {
                                    this.props.form.getFieldValue('manual_input') === 1 &&
                                    getFieldDecorator('receipt_reference', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Payor">
                                {
                                    getFieldDecorator('receipt_partner_id', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <PartnerSelect disabled={!(!this.props.form.getFieldValue('receipt_reference'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Particulars">
                                {
                                    getFieldDecorator('receipt_title', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input disabled={!(!this.props.form.getFieldValue('receipt_reference'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Amount">
                                {
                                    getFieldDecorator('receipt_amount', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: 0,
                                    })(
                                        <Input disabled={!(!this.props.form.getFieldValue('receipt_reference'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <hr />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Mode of Payment">
                                {
                                    getFieldDecorator('receipt_payment_details', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <InputModeOfPayment />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}
                        style={{
                            'display': (this.props.form.getFieldValue('receipt_payment_details').mode_of_payment === 'CHECK' ||
                                this.props.form.getFieldValue('receipt_payment_details').mode_of_payment === 'DEPOSIT'
                            ) ? 'block' : 'none'
                        }}>
                        <Col span={12}>
                            <Form.Item label="Cheque Number"
                                style={{
                                    'display': this.props.form.getFieldValue('receipt_payment_details').mode_of_payment === 'CHECK' ?
                                        'block' : 'none'
                                }}>
                                {
                                    getFieldDecorator('receipt_check_number', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                            <Form.Item label="Bank Account"
                                style={{
                                    'display': this.props.form.getFieldValue('receipt_payment_details').mode_of_payment === 'DEPOSIT' ?
                                        'block' : 'none'
                                }}>
                                {
                                    getFieldDecorator('receipt_bank_account_id', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <BankSelect mode="default" />
                                    )
                                }
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label={this.props.form.getFieldValue('receipt_payment_details').mode_of_payment === 'DEPOSIT' ? 'Deposit Date' : 'Cheque Date'}>
                                {
                                    getFieldDecorator('receipt_payment_date', {
                                        rules: [{ required: false, message: 'Please input!' }]
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>



                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Attachments">
                                {
                                    getFieldDecorator('receipt_documents', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: [],
                                    })(
                                        <FilesUpload upload_url={API_CLIENT.getFileUploadUrl()} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.receipt && prevProps.receipt !== this.props.receipt) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.receipt,
                    receipt_date: moment(this.props.receipt.receipt_date),
                    receipt_payment_date: this.props.receipt.receipt_payment_date ? moment(this.props.receipt.receipt_payment_date) : '',

                });
            }
        }
    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;

                if (handleSubmit) {
                    //console.log(values);

                    // TODO: clear values based on mode_of_payment
                    values = {
                        ...values,
                        receipt_date: values.receipt_date.format('YYYY-MM-DD'),
                        receipt_payment_date: values.receipt_payment_date ? values.receipt_payment_date.format('YYYY-MM-DD') : ''
                    }
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    Record Official Receipt
                </div>
                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        receipt={this.props.receipt}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
