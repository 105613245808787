import React, { Component } from 'react';
import { Input, Drawer, Form, Button, Col, Row, DatePicker, Select, notification, Radio } from 'antd';
import shared_styles from 'shared/constants/styles';
import FundClusterSelect from 'modules/accounting/components/FundClusterSelect';
import BankSelect from 'modules/accounting/components/BankSelect';
import TaxSelect from 'modules/accounting/components/TaxSelect';
import PartnerSelect from 'shared/components/PartnerSelect'
import moment from 'moment';
import accounting from 'accounting';
import DummyInput from 'shared/components/DummyInput';
import BurNumberInput from 'modules/budget/_bur/components/input_BurNumber';
import _ from 'lodash';
const WrappedForm = Form.create()(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                taxes: []
            }
        }
        synchronize_fields = () => {
            // peform calculation on all calculated fields
            this.setState({ taxes: this.props.form.getFieldsValue().voucher_taxes });
        }

        onTaxChange = (value) => {
            //this.setState({taxes: value});
            var tax_breakdown = value;
            var grossAmount = parseFloat(this.props.form.getFieldsValue().voucher_gross_amount || 0);
            var base = grossAmount;
            var vat = _.filter(tax_breakdown, (item) => {
                return item.account_title === 'VAT';
            })
            vat.map((item) => {
                base = base / 1.12; //base-(grossAmount*((item.account_meta.tax_rate)/100))
                return null;
            })

            tax_breakdown = tax_breakdown.map((item) => {
                var value;
                if (item.account_title === 'VAT') {
                    value = grossAmount - grossAmount / 1.12; //grossAmount*((item.account_meta.tax_rate)/100);
                }
                else {
                    value = base * ((item.account_meta.tax_rate) / 100);
                }
                return {
                    id: item.id,
                    account_meta: item.account_meta,
                    account_title: item.account_title,
                    value: value.toFixed(2)
                }
            })

            this.props.form.setFieldsValue({ voucher_tax_breakdown: tax_breakdown });

            // perform computations and set to DummyInput
        }

        onPartnerSelect = (partner) => {

            if (partner.partner_type === 'Supplier') {
                // console.log('Add VAT');
                if (this.props.form.getFieldsValue().voucher_taxes === null) {
                    this.taxField.addVat();
                }
            }

        }

        voucher_bur_number_callback = (bur) => {
            if (bur) {
                this.props.form.setFieldsValue({
                    voucher_partner_id: bur.bur_partner_id,
                    voucher_particulars: bur.bur_particulars,
                    voucher_gross_amount: bur.bur_amount,
                    voucher_taxes: null
                });
                this.onTaxChange([]);
            }
            else {
                this.props.form.setFieldsValue({
                    voucher_partner_id: null,
                    voucher_particulars: null,
                    voucher_gross_amount: null,
                    voucher_taxes: null
                });
                this.onTaxChange([]);
            }

        }

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            var vat = _.filter(form.getFieldsValue().voucher_tax_breakdown, (item) => {
                return item.account_title === 'VAT';
            })
            var other_taxes = _.filter(form.getFieldsValue().voucher_tax_breakdown, (item) => {
                return item.account_title !== 'VAT';
            })
            var grossAmount = parseFloat(form.getFieldsValue().voucher_gross_amount || 0);
            var base = grossAmount;
            var netAmount = grossAmount;
            var totalTax = 0;
            var computedBreakdown = (
                <div>
                    <Row gutter={16}>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <label>GROSS AMOUNT</label>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            {accounting.formatMoney(grossAmount, '₱', 2, ',', '.')}
                        </Col>
                    </Row>
                    <hr />

                    {
                        vat.map((item) => {
                            base = base - item.value
                            // netAmount = base;
                            return (
                                <Row style={{ display: 'none' }} key={item.id} gutter={16}>
                                    <Col span={16} style={{ textAlign: 'right' }}>
                                        <label>{item.account_title} ({item.account_meta.tax_rate}%)</label>
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>
                                        {accounting.formatMoney(item.value, '₱', 2, ',', '.')}
                                    </Col>
                                </Row>
                            )
                        })
                    }

                    {
                        base !== this.props.form.getFieldsValue().voucher_gross_amount &&
                        <Row gutter={16}>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <label>TAX BASE</label>
                            </Col>
                            <Col span={6} style={{ textAlign: 'right' }}>
                                {accounting.formatMoney(base, '₱', 2, ',', '.')}
                            </Col>
                        </Row>
                    }

                    {
                        other_taxes.map((item) => {
                            netAmount = netAmount - parseFloat(item.value);
                            totalTax = totalTax + parseFloat(item.value);
                            console.log(totalTax);
                            return <Row key={item.id} gutter={16}>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <label>{item.account_title} ({item.account_meta.tax_rate}%)</label>
                                </Col>
                                <Col span={6} style={{ textAlign: 'right' }}>
                                    {accounting.formatMoney(item.value, '₱', 2, ',', '.')}
                                </Col>
                            </Row>
                        })
                    }
                    {
                        netAmount !== base &&
                        <Row gutter={16}>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <label>TOTAL TAXES</label>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {accounting.formatMoney(totalTax, '₱', 2, ',', '.')}
                            </Col>
                        </Row>
                    }
                    {
                        this.props.form.getFieldsValue().voucher_penalties > 0 &&
                        <Row gutter={16}>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <label>PENALTIES</label>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {accounting.formatMoney((parseFloat(this.props.form.getFieldsValue().voucher_penalties || 0)), '₱', 2, ',', '.')}
                            </Col>
                        </Row>
                    }
                    <hr />
                    {
                        <Row gutter={16}>

                            <Col span={12} style={{ textAlign: 'right' }}>
                                <label>NET AMOUNT</label>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                {accounting.formatMoney((netAmount - parseFloat(this.props.form.getFieldsValue().voucher_penalties || 0)), '₱', 2, ',', '.')}
                            </Col>
                        </Row>
                    }


                </div>
            )
            console.log(this.props.form.getFieldValue('voucher_bur_number'));
            return (
                <Form layout='vertical'>

                    <Row gutter={16}>
                        {
                            getFieldDecorator('voucher_tax_breakdown', {
                                rules: [{ required: false, message: 'Please input!' }],
                                initialValue: [],
                            })(
                                <DummyInput />
                            )
                        }
                        <Col span={12}>
                            <Form.Item label="Voucher Number">
                                {
                                    getFieldDecorator('voucher_number', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Voucher Date">
                                {
                                    getFieldDecorator('voucher_date', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Check Number">
                                {
                                    getFieldDecorator('voucher_check_number', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Check Date">
                                {
                                    getFieldDecorator('voucher_check_date', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Fund Cluster">
                                {
                                    getFieldDecorator('voucher_fundcluster_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <FundClusterSelect mode="default" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Bank Account">
                                {
                                    getFieldDecorator('voucher_bank_account_id', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <BankSelect mode="default" fundcluster_id={form.getFieldsValue().voucher_fundcluster_id} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        {
                            getFieldDecorator('manual_input', {
                                rules: [{ required: true, message: 'Please input!' }],
                                initialValue: 0,
                            })(
                                <Radio.Group style={{ 'display': 'none' }}>
                                    <Radio value={0}>Search BUR</Radio>
                                    <Radio value={1}>Manual Input</Radio>
                                </Radio.Group>
                            )
                        }
                        <Col span={12}>
                            <Form.Item label="BUR Number">

                                {
                                    this.props.form.getFieldValue('manual_input') === 0 &&
                                    getFieldDecorator('voucher_bur_number', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <BurNumberInput onChange_withObject={this.voucher_bur_number_callback} disabled={this.props.voucher} />
                                    )
                                }
                                {
                                    this.props.form.getFieldValue('manual_input') === 1 &&
                                    getFieldDecorator('voucher_bur_number', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Payee">
                                {
                                    getFieldDecorator('voucher_partner_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <PartnerSelect mode="default" ref={(ref) => { this.partnerField = ref }} onPartnerSelect={this.onPartnerSelect}
                                            disabled={!(!this.props.form.getFieldValue('voucher_bur_number'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Particulars">
                                {
                                    getFieldDecorator('voucher_particulars', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input.TextArea
                                            disabled={!(!this.props.form.getFieldValue('voucher_bur_number'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Gross Amount">
                                {
                                    getFieldDecorator('voucher_gross_amount', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input disabled={!(!this.props.form.getFieldValue('voucher_bur_number'))} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Penalties">
                                {
                                    getFieldDecorator('voucher_penalties', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Taxes">
                                {
                                    _.get(this, 'props.voucher.is_journalled', false)
                                        ? <Select
                                            disabled
                                            mode='multiple'
                                            value={(_.get(this, 'props.voucher.voucher_taxes', []) || []).map(voucher_tax => voucher_tax.id)}
                                            style={{ width: '100%' }}
                                        >
                                            {(_.get(this, 'props.voucher.voucher_taxes', []) || []).map(d => <Select.Option key={d.id} value={d.id}>{d.account_title}</Select.Option>)}
                                        </Select>
                                        : getFieldDecorator('voucher_taxes', {
                                            rules: [{ required: false, message: 'Please input!' }],
                                            initialValue: undefined,
                                        })(
                                            <TaxSelect
                                                mode="multiple"
                                                getFull={true}
                                                onChange={this.onTaxChange}
                                                ref={(ref) => { this.taxField = ref }}
                                            />
                                        )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    {computedBreakdown}

                </Form >
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.voucher && prevProps.voucher !== this.props.voucher) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.voucher,
                    voucher_date: moment(this.props.voucher.voucher_date),
                    voucher_check_date: moment(this.props.voucher.voucher_check_date),
                })
                this.formRef.synchronize_fields();

            }
        }
    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;


                if (handleSubmit) {
                    //console.log(values);
                    var voucher_net_amount = values.voucher_gross_amount;
                    voucher_net_amount -= values.voucher_penalties;
                    values.voucher_tax_breakdown.map((item) => {
                        if (item.account_title !== 'VAT') {
                            voucher_net_amount -= item.value;
                        }
                        return null;
                    })
                    values = {
                        ...values,
                        voucher_date: values.voucher_date.format('YYYY-MM-DD'),
                        voucher_check_date: values.voucher_check_date.format('YYYY-MM-DD'),
                        voucher_account_id: 0, // TODO
                        voucher_net_amount: voucher_net_amount
                    }
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    Record Voucher and Check
                </div>
                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        voucher={this.props.voucher}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
