import axios from 'axios';
import { BASE_URL } from 'shared/constants/urls';

const TIMEOUT = 100000;
const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIMEOUT
});

export const getAuthHeader = function () {
    return { Authorization: 'Bearer ' + sessionStorage.getItem('token') };
}

const API_CLIENT = {
    getFileUploadUrl: function (case_id) {
        return BASE_URL + `/file`;
    },
    login: function (payload) {
        return instance.post('/login', payload);
    },
    // fundcluster
    fundcluster_create: function (payload) {
        return instance.post('/fundcluster', payload, { headers: { ...getAuthHeader() } });
    },
    fundcluster_read: function (fundcluster_id) {
        return instance.get(`/fundcluster/${fundcluster_id}`, { headers: { ...getAuthHeader() } });
    },
    fundcluster_update: function (fundcluster_id, payload) {
        return instance.put(`/fundcluster/${fundcluster_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    fundcluster_list: function (params) {
        return instance.get(`/fundclusters`, { params: params, headers: { ...getAuthHeader() } });
    },
    // account
    account_create: function (payload) {
        return instance.post('/account', payload, { headers: { ...getAuthHeader() } });
    },
    account_read: function (account_id) {
        return instance.get(`/account/${account_id}`, { headers: { ...getAuthHeader() } });
    },
    account_update: function (account_id, payload) {
        return instance.put(`/account/${account_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    account_list: function (params) {
        return instance.get(`/accounts`, { params: params, headers: { ...getAuthHeader() } });
    },
    account_list_all: function (params) {
        return instance.get(`/all_accounts`, { params: params, headers: { ...getAuthHeader() } });
    },
    // subaccount 
    subaccount_create: function (payload) {
        return instance.post('/subaccount', payload, { headers: { ...getAuthHeader() } });
    },
    subaccount_read: function (subaccount_id) {
        return instance.get(`/subaccount/${subaccount_id}`, { headers: { ...getAuthHeader() } });
    },
    subaccount_update: function (subaccount_id, payload) {
        return instance.put(`/subaccount/${subaccount_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    subaccount_delete: function (subaccount_id) {
        return instance.delete(`/subaccount/${subaccount_id}`, { headers: { ...getAuthHeader() } });
    },
    subaccount_list: function (params) {
        return instance.get(`/subaccounts`, { params: params, headers: { ...getAuthHeader() } });
    },
    // voucher
    voucher_create: function (payload) {
        return instance.post('/voucher', payload, { headers: { ...getAuthHeader() } });
    },
    voucher_read: function (voucher_id) {
        return instance.get(`/voucher/${voucher_id}`, { headers: { ...getAuthHeader() } });
    },
    voucher_update: function (voucher_id, payload) {
        return instance.put(`/voucher/${voucher_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    voucher_delete: function (voucher_id) {
        return instance.delete(`/voucher/${voucher_id}`, { headers: { ...getAuthHeader() } });
    },
    voucher_list: function (params) {
        return instance.get(`/vouchers`, { params: params, headers: { ...getAuthHeader() } });
    },
    // receipt
    receipt_create: function (payload) {
        return instance.post('/receipt', payload, { headers: { ...getAuthHeader() } });
    },
    receipt_read: function (receipt_id) {
        return instance.get(`/receipt/${receipt_id}`, { headers: { ...getAuthHeader() } });
    },
    receipt_update: function (receipt_id, payload) {
        return instance.put(`/receipt/${receipt_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    receipt_delete: function (receipt_id) {
        return instance.delete(`/receipt/${receipt_id}`, { headers: { ...getAuthHeader() } });
    },
    receipt_list: function (params) {
        return instance.get(`/receipts`, { params: params, headers: { ...getAuthHeader() } });
    },
    // order_of_payment
    op_list: function (params) {
        return instance.get(`/order_of_payments`, { params: params, headers: { ...getAuthHeader() } });
    },
    op_read: function (op_id) {
        return instance.get(`/order_of_payment/${op_id}`, { headers: { ...getAuthHeader() } });
    },
    op_finalize: function (op_id, payload) {
        return instance.post(`/order_of_payment/${op_id}/finalize`, payload, { headers: { ...getAuthHeader() } });
    },
    op_create: function (payload) {
        return instance.post(`/order_of_payment`, payload, { headers: { ...getAuthHeader() } });
    },
    op_update: function (op_id, payload) {
        return instance.put(`/order_of_payment/${op_id}`, payload, { headers: { ...getAuthHeader() } });
    },

    // pap
    pap_create: function (payload) {
        console.log("U IZ HERE");
        return instance.post('/pap', payload, { headers: { ...getAuthHeader() } });
    },
    pap_read: function (pap_id) {
        return instance.get(`/pap/${pap_id}`, { headers: { ...getAuthHeader() } });
    },
    pap_update: function (pap_id, payload) {
        return instance.put(`/pap/${pap_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    pap_delete: function (pap_id) {
        return instance.delete(`/pap/${pap_id}`, { headers: { ...getAuthHeader() } });
    },
    pap_list: function (params) {
        return instance.get(`/paps`, { params: params, headers: { ...getAuthHeader() } });
    },
    // bur
    bur_create: function (payload) {
        return instance.post('/bur', payload, { headers: { ...getAuthHeader() } });
    },
    bur_read: function (bur_id) {
        return instance.get(`/bur/${bur_id}`, { headers: { ...getAuthHeader() } });
    },
    bur_update: function (bur_id, payload) {
        return instance.put(`/bur/${bur_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    bur_delete: function (bur_id) {
        return instance.delete(`/bur/${bur_id}`, { headers: { ...getAuthHeader() } });
    },
    bur_list: function (params) {
        return instance.get(`/burs`, { params: params, headers: { ...getAuthHeader() } });
    },
    bur_get_by_field: function (field, value) {
        return instance.get(`/bur/get_by_field?field=${field}&value=${value}`, { headers: { ...getAuthHeader() } });
    },
    // journalbatch
    journal_create: function (payload) {
        return instance.post('/journalbatch', payload, { headers: { ...getAuthHeader() } });
    },
    journal_read: function (journalbatch_id) {
        return instance.get(`/journalbatch/${journalbatch_id}`, { headers: { ...getAuthHeader() } });
    },
    journal_update: function (journalbatch_id, payload) {
        return instance.put(`/journalbatch/${journalbatch_id}`, payload, { headers: { ...getAuthHeader() } });
    },
    journal_delete: function (journalbatch_id) {
        return instance.delete(`/journalbatch/${journalbatch_id}`, { headers: { ...getAuthHeader() } });
    },
    journal_list: function (params) {
        return instance.get(`/journalbatches`, { params: params, headers: { ...getAuthHeader() } });
    },
    // ORDER OF PAYMENT
    order_of_payment_get_by_field: function (field, value) {
        return instance.get(`/order_of_payment/get_by_field?field=${field}&value=${value}`, { headers: { ...getAuthHeader() } });
    },

    // REPORTS
    report_general_ledger: function (params) {
        return instance.get(`/report/generalledger`, { params: params, headers: { ...getAuthHeader() } });
    },

    // EXTRA
    partner_list: function (params) {
        return instance.get(`/partners`, { params: params, headers: { ...getAuthHeader() } });
    },

    config_read: function (key, params) {
        return instance.get(`/config/${key}`, { params: params, headers: { ...getAuthHeader() } });
    },
    config_update: function (key, payload) {
        return instance.post(`/config/${key}`, payload, { headers: { ...getAuthHeader() } });
    },

    report_ledger: function (params) {
        return instance.get(`/report/generalledger`, { params: params, headers: { ...getAuthHeader() } });
    }


}

export { instance as axios };

export default API_CLIENT;
