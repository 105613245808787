import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReceiptTable from './components/ReceiptTable';
import FormReceipt from './components/form_Receipt';
import { receipt_list, receipt_create, receipt_update } from '../actions/receipt_actions'
import { notification } from 'antd';
import { INITIAL_PAGINATION } from 'shared/constants/definitions';

class Receipts extends Component {

    constructor(props) {
        super(props);
        this.state = { form_visible: false, currentEdit: null };
        this.DEFAULT_LIST_PARAMS = {
            ...INITIAL_PAGINATION, sort_key: 'receipt_date', sort_dir: 'descend'
        }
    }

    showForm = () => {
        this.setState({ form_visible: true });
    };

    hideForm = () => {
        this.form.reset();
        this.setState({ form_visible: false, currentEdit: null });
    };

    componentDidMount() {
        this.props.receipt_list({ ...this.DEFAULT_LIST_PARAMS });
    }

    onSearch = (q) => {
        this.props.receipt_list({
            ...this.DEFAULT_LIST_PARAMS,
            q: q,
        });
    }

    onSubmit = (values) => {
        console.log('onSubmit', values);
        if (this.state.currentEdit) {
            this.props.receipt_update(this.state.currentEdit.id, values, this.callbackSubmit);
        }
        else {
            this.props.receipt_create(values, this.callbackSubmit);
        }
    }

    callbackSubmit = (data) => {
        if (data.receipt) {
            notification['success']({ message: 'Success!' });
            this.hideForm();
        }
        else {
            notification['error']({ message: data.message });
        }
    }

    onEdit = (index) => {
        console.log('onEdit', this.props.receipts.data[index]);
        this.setState({ currentEdit: this.props.receipts.data[index], form_visible: true });
    }

    onDelete = (index) => {
        console.log('TODO: onDelete', this.props.receipts.data[index]);
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.receipt_list({
            ...this.props.receipts.meta,
            take: pagination.pageSize,
            page: pagination.current,
            sort_key: sorter.columnKey,
            sort_dir: sorter.order,
            ...filters
        })
    }

    render() {
        return (
            <div>
                <ReceiptTable
                    receipts={this.props.receipts}
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    onTableChange={this.handleTableChange}
                    onSearch={this.onSearch}
                    showForm={this.showForm}
                    user={this.props.user}
                />
                <FormReceipt
                    visible={this.state.form_visible}
                    onCancel={this.hideForm}
                    onSubmit={this.onSubmit}
                    ref={(ref) => { this.form = ref }}
                    receipt={this.state.currentEdit}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        receipts: state.receiptListReducer,
        user: state.loggedInUser.user,
    };
}

const mapDispatchToProps = {
    receipt_list, receipt_create, receipt_update
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
