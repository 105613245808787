import axios from 'axios';
import { BASE_URL } from 'shared/constants/urls';
import { getAuthHeader } from './api_client'
export const getError = (error) => {
    let message, errorCode, httpStatus;
    console.log(error);
    if (error.response) {
        const response = error.response;
        const data = response.data;
        errorCode = data.error ? data.error : 'no_code';
        const messages = data.messages;
        httpStatus = response.status;
        message = (messages && messages.length > 0) ?
            messages[0] : 'Something went wrong. Try Again!';
    } else if (typeof error === 'string') {
        errorCode = 'no_code';
        message = error;
    } else {
        message = 'Something went wrong. Try Again!';
    }
    return {
        errorCode: errorCode,
        message,
        httpStatus: httpStatus || 0
    };
}

export const make_api_call = (dispatch, api_call, action, callback = null) => {
    // console.log("make api call");
    api_call
        .then((response) => {
            dispatch({ type: `${action}_SUCCESS`, payload: response.data });
            if (callback != null) {
                callback(response.data);
            }
        })
        .catch((error) => {
            dispatch({ type: `${action}_FAILURE`, payload: getError(error) });
            if (callback != null) {
                callback(getError(error));
            }
        });
}

export const download_file = (file) => {
    download_url('/file?location=' + file.url);
}

export const download_url = (url, name = 'download.pdf') => {
    axios({
        url: BASE_URL + url,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            ...getAuthHeader()
        }
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    });
}
