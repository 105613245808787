import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actionCreators'
import { OAUTH_LOGOUT_URL } from 'shared/constants/urls';


class Logout extends Component {
    componentDidUpdate(prevProps) {
        this.props.logout();
        //this.props.history.push('/login');
        window.location.href = OAUTH_LOGOUT_URL;

    }

    componentDidMount() {
        this.props.logout();
        //this.props.history.push('/login');
        window.location.href = OAUTH_LOGOUT_URL;
    }

    render() {
        return (
            <span>
                Logout success.
            </span>
        );
    }
}

const mapStateToProps = state => ({
    loginLoading: state.loginLoading,
    loggedInUser: state.loggedInUser,
    loginError: state.loginError
})

const mapDispatchToProps = {
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
