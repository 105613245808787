import React, { Component } from 'react';
import { Input, Drawer, Form, Button, Col, Row, Select, DatePicker, notification, Icon } from 'antd';
import shared_styles from 'shared/constants/styles';
import BankSelect from 'modules/accounting/components/BankSelect';
import moment from 'moment';
import _ from 'lodash';
import API_CLIENT from 'shared/utils/api_client';
import JournalTransactionsInput from './input_JournalTransactions';

const SOURCE_OPTIONS = [
    {
        value: "VOUCHER",
        label: "VOUCHER",
        types: [
            { value: "CKDJ", label: "CKDJ" }
        ]
    },
    {
        value: "OFFICIAL_RECEIPT",
        label: "OFFICIAL_RECEIPT",
        types: [
            { value: "CRJ", label: "CRJ" }
        ]
    },
    {
        value: "MANUAL",
        label: "MANUAL",
        types: [
            { value: "CRJ", label: "CRJ" },
            { value: "CDJ", label: "CDJ" },
            { value: "CKDJ", label: "CKDJ" },
            { value: "JEV", label: "JEV" },
        ]
    }
]


const WrappedForm = Form.create()(
    class extends Component {

        synchronize_controls(val, key) {
            console.log(val);
            var values = { ...this.props.form.getFieldsValue(), [key]: val };
            this.setState(values);
            if (key === 'journalbatch_source') {
                this.props.form.setFieldsValue(
                    {
                        journalbatch_type: null,
                        bank_account_id: null,
                        start_date: null,
                        end_date: null
                    }
                )
                const source_option_types = _.keyBy(SOURCE_OPTIONS, 'value');

                if (source_option_types[val].types.length === 1) {
                    this.props.form.setFieldsValue({
                        journalbatch_type: source_option_types[val].types[0].value
                    })
                }

            }
            else if (key === 'journalbatch_type') {
                this.props.form.setFieldsValue(
                    {
                        bank_account_id: null,
                        start_date: null,
                        end_date: null
                    }
                )
            }
        }

        populate = () => {
            // check the inputs
            var start_date = this.props.form.getFieldValue('start_date').format('YYYY-MM-DD');
            var end_date = this.props.form.getFieldValue('end_date').format('YYYY-MM-DD');
            var bank_account_id = this.props.form.getFieldValue('bank_account_id');

            if (this.props.form.getFieldValue('journalbatch_source') === 'OFFICIAL_RECEIPT') {
                if (!(start_date && end_date)) {
                    notification['error']({ message: 'Incomplete parameters to get ORs!' }); return;
                }
                API_CLIENT.receipt_list({ start_date: start_date, end_date: end_date, is_journalled: [0] })
                    .then((response) => {
                        if (response.data.data.length === 0) {
                            notification['error']({ message: 'No receipt pending for journal entry matches your search' });
                        }
                        this.receiptPopulate(response.data.data);
                    })
                    .catch((error) => {
                        notification['error']({ message: error.message });
                    });
            }
            if (this.props.form.getFieldValue('journalbatch_source') === 'VOUCHER') {
                if (!(start_date && end_date && bank_account_id)) {
                    notification['error']({ message: 'Incomplete parameters to get Vouchers!' });
                }
                API_CLIENT.voucher_list({ start_date: start_date, end_date: end_date, voucher_bank_account_id: [bank_account_id], is_journalled: [0] })
                    .then((response) => {
                        if (response.data.data.length === 0) {
                            notification['error']({ message: 'No voucher pending for journal entry matches your search' });
                        }
                        this.voucherPopulate(response.data.data);
                    })
                    .catch((error) => {
                        notification['error']({ message: error.message });
                    });
            }
        }

        voucherPopulate = (data) => {
            var transactions = [];
            data.map((item) => {
                var items = []
                items.push({
                    credit: item.voucher_net_amount,
                    debit: 0,
                    journalitem_account_id: item.voucher_bank_account_id
                });
                if (item.voucher_penalties) {
                    items.push({
                        credit: item.voucher_penalties,
                        debit: 0,
                        journalitem_account_id: 11 // Fines and Penalties   
                    });
                }
                item.voucher_tax_breakdown.map((tax) => {
                    if (tax.account_title !== 'VAT') {
                        items.push({
                            credit: tax.value,
                            debit: 0,
                            journalitem_account_id: tax.id
                        });
                    }
                    return null;
                })
                transactions.push({
                    items: items,
                    journaltransaction_reference: item.voucher_number,
                    journaltransaction_particulars: item.voucher_particulars,
                    journaltransaction_entity_id: item.voucher_partner_id,
                    journaltransaction_entity_type: 'PARTNER'
                });
                return null;
            });
            this.props.form.setFieldsValue(
                { transactions: transactions }
            );
        }

        receiptPopulate = (data) => {
            var transactions = [];
            data.map((item) => {
                var items = [
                    {
                        credit: 0,
                        debit: item.receipt_amount,
                        journalitem_account_id: 1
                    }
                ]
                transactions.push({
                    items: items,
                    journaltransaction_reference: item.receipt_number,
                    journaltransaction_particulars: item.receipt_title,
                    journaltransaction_entity_id: item.receipt_partner_id,
                    journaltransaction_entity_type: 'PARTNER'
                });
                return null;
            });
            this.props.form.setFieldsValue(
                { transactions: transactions }
            );
        }



        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;

            const fieldsValue = this.props.form.getFieldsValue();
            const source_option_types = _.keyBy(SOURCE_OPTIONS, 'value');
            var initial_journalbatch_source = '';
            var initial_journalbatch_type = '';
            if (this.props.isInitialize) {
                initial_journalbatch_source = 'MANUAL'
                initial_journalbatch_type = 'JEV'
            }
            return (
                <Form layout='vertical'>

                    <Row gutter={16} >
                        <Col span={12}>
                            <Row gutter={8}>
                                <Col span={8} style={{ display: this.props.isInitialize ? 'none' : 'visible' }}>
                                    <Form.Item label="Source">
                                        {
                                            getFieldDecorator('journalbatch_source', {
                                                rules: [{ required: true, message: 'Please input!' }],
                                                initialValue: initial_journalbatch_source,
                                            })(
                                                <Select onChange={(val) => { this.synchronize_controls(val, 'journalbatch_source') }}>
                                                    {
                                                        SOURCE_OPTIONS.map((item) => {
                                                            return <Select.Option
                                                                disabled={form.getFieldValue('transactions') && form.getFieldValue('transactions').length > 0}
                                                                key={item.value} value={item.value}>{item.label}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={8} style={{ display: this.props.isInitialize ? 'none' : 'visible' }}>
                                    <Form.Item label="Type">
                                        {
                                            getFieldDecorator('journalbatch_type', {
                                                rules: [{ required: true, message: 'Please input!' }],
                                                initialValue: initial_journalbatch_type,
                                            })(
                                                <Select onChange={(val) => { this.synchronize_controls(val, 'journalbatch_type') }}>
                                                    {
                                                        source_option_types[this.props.form.getFieldValue('journalbatch_source')] &&
                                                        source_option_types[this.props.form.getFieldValue('journalbatch_source')].types.map((item) => {
                                                            return <Select.Option
                                                                disabled={form.getFieldValue('transactions') && form.getFieldValue('transactions').length > 0}
                                                                key={item.value} value={item.value}>{item.label}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Journal Entry Date">
                                        {
                                            getFieldDecorator('journalbatch_date', {
                                                rules: [{ required: true, message: 'Please input!' }],
                                            })(
                                                <DatePicker />
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8} style={{ display: fieldsValue.journalbatch_type !== 'CKDJ' && 'none' }}>
                                    <Form.Item label="Bank Account">
                                        {
                                            getFieldDecorator('bank_account_id', {
                                                rules: [{ required: false, message: 'Please input!' }],
                                                initialValue: null
                                            })(
                                                <BankSelect labelInValue={false} />
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ display: !(fieldsValue.journalbatch_source === 'VOUCHER' || fieldsValue.journalbatch_source === 'OFFICIAL_RECEIPT') && 'none' }}>
                                    <Form.Item label="Start">
                                        {
                                            getFieldDecorator('start_date', {
                                                rules: [{ required: false, message: 'Please input!' }],
                                            })(
                                                <DatePicker />
                                            )
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ display: !(fieldsValue.journalbatch_source === 'VOUCHER' || fieldsValue.journalbatch_source === 'OFFICIAL_RECEIPT') && 'none' }}>
                                    <Form.Item label="End">
                                        {
                                            getFieldDecorator('end_date', {
                                                rules: [{ required: false, message: 'Please input!' }],
                                            })(
                                                <DatePicker />
                                            )
                                        }

                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ display: (fieldsValue.journalbatch_source === 'MANUAL' || fieldsValue.journalbatch_source === '') && 'none' }}>
                                    <Button style={{ marginTop: 29 }} onClick={this.populate}><Icon type="download" /></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            {
                                this.props.isInitialize !== true &&
                                <Form.Item label="Notes">
                                    {
                                        getFieldDecorator('journalbatch_notes', {
                                            rules: [{ required: false, message: 'Please input!' }],
                                        })(
                                            <Input.TextArea rows={4} />
                                        )
                                    }

                                </Form.Item>
                            }
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Form.Item label="">
                            {
                                getFieldDecorator('transactions', {
                                    rules: [{ required: true, message: 'Please input!' }],
                                    initialValue: []
                                })(
                                    <JournalTransactionsInput
                                        source={this.props.form.getFieldValue('journalbatch_source')}
                                        type={this.props.form.getFieldValue('journalbatch_type')}
                                        isInitialize={this.props.isInitialize}
                                    />
                                )
                            }

                        </Form.Item>
                    </Row>

                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate');
        if (this.props.journalbatch && prevProps.journalbatch !== this.props.journalbatch) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.journalbatch,
                    journalbatch_date: moment(this.props.journalbatch.journalbatch_date),
                    start_date: moment(this.props.journalbatch.journalbatch_metadata.start_date),
                    end_date: moment(this.props.journalbatch.journalbatch_metadata.end_date),
                    bank_account_id: this.props.journalbatch.journalbatch_metadata.bank_account_id

                })
            }
        }
        else if (this.props.isInitialize) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    transactions: [
                        {
                            debit: 0,
                            credit: 0,
                            journaltransaction_reference: 'INITIALIZATION',
                            journaltransaction_particulars: 'INITIALIZATION'
                        }
                    ]

                })
            }
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            bank_lookup: []
        }

    }

    componentDidMount = () => {
        API_CLIENT.subaccount_list({ is_active: [1], account_special_type: ['BANK'] })
            .then((response) => {
                this.setState({ bank_lookup: _.keyBy(response.data.data, 'id') });
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });

    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            console.log('====', err, values);
            if (this.props.journalbatch) {
                notification['error']({ message: 'Cannot Edit!' });
                return;
            }
            if (!err) {
                const handleSubmit = this.props.onSubmit;
                // Transactions Pre-processing:
                var transactions = values.transactions.map((item) => {
                    var result = item;
                    result.journaltransaction_reference_type = values.journalbatch_source;
                    if (values.journalbatch_type === 'CRJ') {
                        result.journaltransaction_group = 'CRJ';
                    }
                    else if (values.journalbatch_type === 'JEV') {
                        result.journaltransaction_group = result.journaltransaction_reference;
                    }
                    else if (values.journalbatch_type === 'CDJ') {
                        result.journaltransaction_group = 'CDJ';
                    }
                    else if (values.journalbatch_type === 'CKDJ') {
                        result.journaltransaction_group = 'CKDJ#' + this.state.bank_lookup[values.bank_account_id].account_title;
                    }
                    return result;
                });

                var debit_total = 0;
                var credit_total = 0;
                values.transactions.map((transaction) => {
                    transaction.items.map((item) => {
                        debit_total += parseFloat(item.debit);
                        credit_total += parseFloat(item.credit);
                        return null;
                    });
                    return null;
                });

                // console.log(credit_total - debit_total);

                if (Math.abs(credit_total - debit_total) > 0.0000001) {
                    notification['error']({ message: 'Debit(' + debit_total + ') not equal to Credit(' + credit_total + ')' });
                    return;
                }

                if (handleSubmit) {
                    //console.log(values);
                    values = {
                        ...values,
                        journalbatch_metadata: {
                            start_date: values.start_date ? values.start_date.format('YYYY-MM-DD') : null,
                            end_date: values.end_date ? values.end_date.format('YYYY-MM-DD') : null,
                            bank_account_id: values.bank_account_id
                        },
                        journalbatch_date: values.journalbatch_date.format('YYYY-MM-DD'),
                        transactions: transactions
                    }
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        return (
            <Drawer
                width={'100%'}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    {this.props.isInitialize ? 'Initialize Trial Balance' : 'Record Journal Entry'}
                </div>
                <div>
                    <WrappedForm
                        isInitialize={this.props.isInitialize}
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
