export const OAUTH_URL = process.env.REACT_APP_LOGIN_URL + '/#/oauth?redirect_uri='
    + encodeURIComponent(process.env.REACT_APP_AUTH_REDIRECT_URL)
    + '&client_id=' + process.env.REACT_APP_AUTH_CLIENT_ID;
export const OAUTH_LOGOUT_URL = process.env.REACT_APP_LOGIN_URL + '/#/logout';
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const API_PARTNER = '/partner';
export const API_PARTNERS = '/partners';
export const API_ESTIMATES = '/estimates';
export const API_OP = '/order_of_payment';
export const API_OPS = '/order_of_payments';
export const API_FILE = '/file';
export const API_LEDGER = '/general_ledger';
export const API_NOTIFICATIONS = '/notifications';
export const API_NOTIFICATION = '/notification';
export const API_NOTIFICATIONSCLEAR = '/notification/clear';
// https://api-ppcd.npo.staging.acctechnology.ph/api/general_ledger

export const API_FILE_UPLOAD = `${BASE_URL}${API_FILE}`;
