import React, { Component } from 'react';
import { Input, Drawer, Form, Button, Col, Row, notification, DatePicker } from 'antd';
import shared_styles from 'shared/constants/styles';
import PartnerSelect from 'shared/components/PartnerSelect'
import FundClusterSelect from 'modules/accounting/components/FundClusterSelect';
import BankSelect from 'modules/accounting/components/BankSelect';
import moment from 'moment';

const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Bill Number">
                                {
                                    getFieldDecorator('op_bill_number', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Fund Cluster">
                                {
                                    getFieldDecorator('op_fundcluster_id', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <FundClusterSelect mode="multiple" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Bank Account">
                                {
                                    getFieldDecorator('bank_accounts', {
                                        rules: [{ required: this.props.banks_required, message: 'Please input!' }]
                                    })(
                                        <BankSelect mode="multiple" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Charge To">
                                {
                                    getFieldDecorator('op_partner_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <PartnerSelect mode="default" />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item label="Order of Payment Date">
                                {
                                    getFieldDecorator('op_date', {
                                        rules: [{ required: true, message: 'Please input!' }]
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Particulars">
                                {
                                    getFieldDecorator('op_particulars', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input.TextArea />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Amount">
                                {
                                    getFieldDecorator('op_amount', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    state = {
        banks_required: false
    }

    componentDidUpdate(prevProps) {
        if (this.props.order_of_payment && prevProps.order_of_payment !== this.props.order_of_payment) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.order_of_payment,
                    op_date: moment(this.props.order_of_payment.op_date)
                })
            }
        }
    }

    handleSubmitAsDraft = () => {
        // console.log('handleSubmit');
        this.setState({ banks_required: false },
            () => {
                this.formRef.props.form.validateFields(['bank_accounts'], { force: true })
            });
        setTimeout(function () { //Start the timer
            this.formRef.props.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    const handleSubmit = this.props.onSubmit;

                    if (handleSubmit) {
                        //console.log(values);
                        values = {
                            ...values,
                            op_date: values.op_date.format('YYYY-MM-DD')
                        }
                        handleSubmit(values);
                    }
                }
                else {
                    notification['error']({ message: 'Please input all values' });
                }
            });
        }.bind(this), 10)

    }


    handleSubmit = () => {
        // console.log('handleSubmit');
        this.setState({ banks_required: true },
            () => {
                this.formRef.props.form.validateFields(['bank_accounts'], { force: true })
            });
        setTimeout(function () { //Start the timer
            this.formRef.props.form.validateFields((err, values) => {
                console.log(err, values);
                if (!err) {
                    const handleSubmit = this.props.onSubmit;

                    if (handleSubmit) {
                        //console.log(values);
                        values = {
                            ...values,
                            op_date: values.op_date.format('YYYY-MM-DD'),
                            finalize: true
                        }
                        handleSubmit(values);
                    }
                }
                else {
                    notification['error']({ message: 'Please input all values' });
                }
            });
        }.bind(this), 10)

    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        // console.log(this.props.order_of_payment)
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    Create Order of Payment
                </div>
                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        user={this.props.user}
                        banks_required={this.state.banks_required}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Finalize</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmitAsDraft} type="primary">Save as Draft</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
