import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './components/style.css';
import FundClusters from './_fundcluster';
import Taxes from './_tax';
import ChartOfAccounts from './_coa';
import BankAccounts from './_bank';
import _ from 'lodash';

const { Content } = Layout;


const MENU_ITEMS = [
    { title: 'Fund Clusters', value: 'accounting', permission: 'fmd_view_fundcluster' },
    { title: 'Chart of Accounts', value: 'accounting/coa', permission: 'fmd_view_account' },
    { title: 'Tax Management', value: 'accounting/tax', permission: 'fmd_view_tax' },
    { title: 'Bank Accounts', value: 'accounting/bank', permission: 'fmd_view_bank' },
]

class Accounting extends Component {

    getMenu = () => {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <Menu
                mode="horizontal"
                style={{ fontSize: 18 }}
                onClick={this.onClick_Menu}
                defaultSelectedKeys={['Fund Clusters']}
            >
                {
                    MENU_ITEMS.map((item) => {
                        if (item.permission && !permissions.includes(item.permission)) {
                            return null;
                        }
                        return (
                            <Menu.Item key={item.title} >
                                <NavLink to={`/${item.value}`} style={{ fontWeight: '200', color: 'black' }} className="navigatorAccoun">
                                    {item.title}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
    }


    render() {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <div>
                <Layout className="layout">
                    {this.getMenu()}

                    <Content style={{ background: "white", paddingTop: '30px' }} >
                        <HashRouter>
                            <Switch>
                                {
                                    permissions.includes('fmd_view_fundcluster') && <Route path="/accounting" exact component={FundClusters} />
                                }
                                {
                                    permissions.includes('fmd_view_account') && <Route path="/accounting/coa" exact component={ChartOfAccounts} />
                                }
                                {
                                    permissions.includes('fmd_view_tax') && <Route path="/accounting/tax" exact component={Taxes} />
                                }
                                {
                                    permissions.includes('fmd_view_bank') && <Route path="/accounting/bank" exact component={BankAccounts} />
                                }
                                {
                                    permissions.includes('fmd_view_fundcluster') && <Route path="/" exact component={FundClusters} />
                                }
                            </Switch>
                        </HashRouter>
                    </Content>
                </Layout>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loggedInUser: state.loggedInUser,
    })
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Accounting);

