import React, { Component } from 'react';
import { connect } from 'react-redux';

import BankTable from './components/BankTable';
import { bank_list, bank_create, bank_update, bank_delete } from '../actions/bank_actions';
import { notification } from 'antd';
import { NO_PAGINATION } from 'shared/constants/definitions';

class BankAccounts extends Component {
    componentDidMount() {
        this.props.bank_list({ ...NO_PAGINATION, account_special_type: ['BANK'] });
    }

    onSearch = (q) => {
        this.props.bank_list({ ...NO_PAGINATION, account_special_type: ['BANK'], ...this.props.banks.meta, q: q });
        this.bankTable.reset();
    }

    onCreate = (item) => {
        //console.log("TODO: create: ", item);
        this.props.bank_create(item, data => {
            if (data.account) {
                notification['success']({ message: 'Created!' });
                this.bankTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        });
    }

    onUpdate = (item) => {
        this.props.bank_update(item.id, item, data => {
            if (data.account) {
                notification['success']({ message: 'Updated!' });
                this.bankTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        })
    }

    onDelete = (item) => {
        console.log("TODO: delete: ", item);
        this.props.bank_delete(item.id, data => {
            if (data.account) {
                notification['success']({ message: 'Deleted!' });
            }
            else {
                notification['error']({ message: data.message });
            }

        })
    }

    render() {
        return (
            <BankTable
                banks={this.props.banks}
                onSearch={this.onSearch}
                ref={(ref) => { this.bankTable = ref; }}
                onCreate={this.onCreate}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                user={this.props.user}
            />
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        banks: state.bankListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    bank_list, bank_create, bank_update, bank_delete
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts);
