import React, { Component } from 'react';
import { connect } from 'react-redux';

import TaxTable from './components/TaxTable';
import { tax_list, tax_create, tax_update, tax_delete } from '../actions/tax_actions';
import { notification } from 'antd';
import { NO_PAGINATION } from 'shared/constants/definitions';

class Taxes extends Component {
    componentDidMount() {
        this.props.tax_list({ ...NO_PAGINATION, account_special_type: ['TAX'] });
    }

    onSearch = (q) => {
        this.props.tax_list({ ...NO_PAGINATION, account_special_type: ['TAX'], ...this.props.taxes.meta, q: q });
        this.taxTable.reset();
    }

    onCreate = (item) => {
        //console.log("TODO: create: ", item);
        this.props.tax_create(item, data => {
            if (data.account) {
                notification['success']({ message: 'Created!' });
                this.taxTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        });
    }

    onUpdate = (item) => {
        this.props.tax_update(item.id, item, data => {
            if (data.account) {
                notification['success']({ message: 'Updated!' });
                this.taxTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        })
    }

    onDelete = (item) => {
        console.log("TODO: delete: ", item);
        this.props.tax_delete(item.id, data => {
            if (data.account) {
                notification['success']({ message: 'Deleted!' });
            }
            else {
                notification['error']({ message: data.message });
            }

        })
    }

    render() {
        return (
            <TaxTable
                taxes={this.props.taxes}
                onSearch={this.onSearch}
                ref={(ref) => { this.taxTable = ref; }}
                onCreate={this.onCreate}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                user={this.props.user}
            />
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        taxes: state.taxListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    tax_list, tax_create, tax_update, tax_delete
}

export default connect(mapStateToProps, mapDispatchToProps)(Taxes);
