import React, { Component } from 'react';

export default class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || undefined;
        this.state = {
            value: value,
        };
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            // console.log('DummyInput', props.value);
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    clear = () => {
        this.setState({ value: undefined });
    }

    render() {
        return (
            <div></div>
        )

    }
}