import React, { Component } from 'react';
import { Table, Input, Popconfirm, Form, Icon, Button, Switch, Checkbox, Popover } from 'antd';
import '../../components/style.css';
import IsActive from 'shared/components/IsActive';
import { IS_ACTIVE_OPTIONS } from 'shared/constants/definitions';
import moment from 'moment';
import { get } from 'lodash';
import _ from 'lodash';
const Search = Input.Search;
const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr  {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends Component {

    getInput = () => {
        var permissions = _.get(this.props.user, 'permissions.accounting', []);
        if (this.props.dataIndex === 'is_active') {
            return <Switch disabled={!permissions.includes('fmd_deactivate_fundcluster')} />
        }
        else if (this.props.dataIndex === 'id' && this.props.record[this.props.dataIndex]) {
            return <Input disabled={true} />
        }
        return <Input />;
    };

    getInitialValue = () => {
        if (this.props.dataIndex === 'is_active' && (this.props.record['id'])) {
            return this.props.record[this.props.dataIndex];
        }
        else if (this.props.dataIndex === 'is_active') {
            return true;
        }
        return this.props.record[this.props.dataIndex];
    }

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            text,
            record,
            index,
            valuePropName,
            ...restProps
        } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {
                                        getFieldDecorator(dataIndex, {
                                            rules: [{
                                                required: true,
                                                message: `Please Input ${title}!`,
                                            }],
                                            valuePropName: valuePropName,
                                            initialValue: this.getInitialValue(dataIndex),
                                        })(this.getInput())
                                    }
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class ListTable extends Component {

    constructor(props) {
        super(props);
        this.permissions = _.get(this.props.user, 'permissions.accounting', []);
        this.state = {
            toBeInsertedData: null,
            editingKey: '',
            hideColumns: []
        };

        this.columns = [{
            title: 'FUND CLUSTER',
            dataIndex: 'id',
            key: 'id',
            editable: true,
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'fundcluster_description',
            width: '40%',
            key: 'fundcluster_description',
            editable: true,
        },
        {
            title: 'STATUS',
            dataIndex: 'is_active',
            editable: true,
            key: 'is_active',
            valuePropName: 'checked',
            filters: IS_ACTIVE_OPTIONS,
            onFilter: (value, record) => value.includes(record.is_active),
            // filteredValue: ['true'],
            render: (text, record, index) => {
                return <IsActive value={text} />
            }
        },
        {
            title: 'CREATED',
            dataIndex: 'created_at',
            key: 'created_at',
            editable: false,
            render: (value) => {
                return value ? moment(value).format('YYYY-MM-DD') : '';
            }
        },
        {
            title: 'MODIFIED',
            dataIndex: 'updated_at',
            key: 'updated_at',
            editable: false,
            render: (value) => {
                return value ? moment(value).format('YYYY-MM-DD') : '';
            }
        },
        {
            title: ' ',
            dataIndex: 'action',
            render: (text, record) => {
                const editable = this.isEditing(record);
                return (
                    <div>
                        {editable ? (
                            <span>
                                <EditableContext.Consumer>
                                    {form => (
                                        <button className="link-button"
                                            onClick={() => this.save(form, record.index)}
                                            style={{ marginRight: 8 }}>
                                            <Icon type="check" style={{ color: "green" }} />
                                        </button>
                                    )}
                                </EditableContext.Consumer>
                                <Popconfirm
                                    title="Sure to cancel?"
                                    onConfirm={() => this.cancel(record.index)} >
                                    <button className="link-button"><Icon type="close" style={{ color: "red" }} /></button>
                                </Popconfirm>
                            </span>
                        ) : (
                                <div>
                                    {
                                        this.permissions.includes('fmd_edit_fundcluster') &&
                                        <button className="link-button" onClick={() => this.edit(record.index)}>
                                            <Icon type="edit" style={{}} className="editDeleteButtons" />
                                        </button>
                                    }
                                    &nbsp;&nbsp;
                                    {
                                        // <a onClick={()=> this.delete(record)}><Icon type ="delete" style={{}}/></a>
                                    }
                                </div>
                            )}
                    </div>
                );
            },
        },
        ];
    }

    isEditing = (record) => {
        return record.index === this.state.editingKey;
    };

    edit(key) {
        this.setState({ toBeInsertedData: null, editingKey: key });
    }

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            if (this.state.toBeInsertedData) {
                // console.log('create: ', row);
                this.props.onCreate(row);

            } else {
                // console.log('update: ', row);
                this.props.onUpdate(row);
            }
        });
    }

    reset() {
        this.setState({ toBeInsertedData: null, editingKey: '' });
    }

    cancel = (key) => {
        // console.log("cancel: " + key);
        this.setState({ toBeInsertedData: null, editingKey: '' });
    };

    add = () => {
        const value = this.props.fundclusters.data;
        if (this.state.toBeInsertedData) {
            return; // there is currently one to be inserted (do not add another)
        }
        const keys = this.columns.map(column => column.dataIndex);
        const newObj = keys.reduce((o, key) => ({ ...o, [key]: '' }), {})
        newObj.index = value.length;
        this.setState({ toBeInsertedData: newObj, editingKey: value.length })
    }

    delete = (row) => {
        this.props.onDelete(row);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.key))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    [{ title: 'CREATED', key: 'created_at' }, { title: 'MODIFIED', key: 'updated_at' }].map(column => {
                        const checked = !this.state.hideColumns.includes(column.key);
                        return (
                            <div key={column.key}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.key)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.filteredColumns().map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                    valuePropName: !col.valuePropName ? 'value' : col.valuePropName,
                    user: this.props.user,
                }),
            };
        });

        var data
        if (this.props.fundclusters.data) {
            data = this.props.fundclusters.data.slice();
            if (this.state.toBeInsertedData != null) {
                //console.log(this.state.toBeInsertedData);
                data.push(this.state.toBeInsertedData);
            }
            if (data) {
                data = data.map((item, index) => {
                    return { index: index, ...item };
                });
            }
            //console.log(data);
        }
        return (

            <div>
                <div>
                    <Search
                        placeholder="Search for Fund Cluster"
                        onSearch={value => this.props.onSearch(value)}
                        style={{ width: 400 }} />
                    <div className="pull-right">
                        <Popover
                            title='Configure Columns'
                            content={this.columnsPopOver()}
                            trigger='click'>
                            <Button
                                style={{ margin: '0px 10px' }}
                                shape='circle'
                                size='large'
                                icon='setting'
                            />
                        </Popover>
                        {
                            this.permissions.includes('fmd_add_fundcluster') &&
                            <Button
                                type="primary"
                                className="btn btn-primary"
                                onClick={this.add} >
                                Create Fund Cluster
                            </Button>
                        }

                    </div>
                </div>

                <Table
                    style={{ marginTop: '30px' }}
                    components={components}
                    dataSource={data}
                    columns={columns}
                    size="middle"
                    rowClassName="editable-row"
                    rowKey="index"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: get(this.props, 'taxes.meta.total', 0)
                    }}
                />
            </div>
        );
    }
}

export default ListTable;
