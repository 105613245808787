import React, { Component } from 'react';
import { Table, Icon, Button, Input, Popover, Checkbox } from 'antd';
import { get } from 'lodash';
import accounting from 'accounting';
import _ from 'lodash';

const { Search } = Input;

export default class VoucherTable extends Component {
    constructor(props) {
        super(props);
        this.permissions = _.get(this.props.user, 'permissions.accounting', []);

        this.state = {
            hideColumns: []
        }
        this.columns = [
            {
                title: "Voucher Number",
                dataIndex: "voucher_number",
                sorter: true
            },
            {
                title: "Voucher Date",
                dataIndex: "voucher_date",
                sorter: true
            },
            {
                title: "BUR Number",
                dataIndex: "voucher_bur_number"
            },
            {
                title: "Payee",
                dataIndex: "partner_name"
            },
            {
                title: "Particulars",
                dataIndex: "voucher_particulars"
            },
            {
                title: "Amount Due",
                dataIndex: "voucher_net_amount",
                render: value => accounting.formatMoney(value, '₱', 2, ',', '.')
            },
            {
                title: "",
                dataIndex: "action",
                render: (value, record, index) => {
                    return <div>
                        {
                            this.permissions.includes('fmd_edit_voucher') &&
                            <button className="link-button" onClick={() => this.edit(index)}>
                                <Icon type="edit" style={{}} className="editDeleteButtons" />
                            </button>
                        }
                        &nbsp;&nbsp;
                        {
                            //<a onClick={()=> this.delete(index)}><Icon type ="delete" style={{}}/></a>
                        }
                    </div>
                }
            }

        ]
    }

    edit = (index) => {
        this.props.onEdit(index);
    }

    delete = (index) => {
        this.props.onDelete(index);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.dataIndex))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.columns.filter(column => column.dataIndex !== 'action').map(column => {
                        const checked = !this.state.hideColumns.includes(column.dataIndex);
                        return (
                            <div key={column.dataIndex}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.dataIndex)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const columns = this.filteredColumns();
        var data = this.props.vouchers.data;
        return (
            <div>
                <div>
                    <Search
                        placeholder="Search for Voucher"
                        onSearch={value => this.props.onSearch(value)}
                        style={{ width: 400 }} />
                    <div className="pull-right">
                        <Popover
                            title='Configure Columns'
                            content={this.columnsPopOver()}
                            trigger='click'>
                            <Button
                                style={{ margin: '0px 10px' }}
                                shape='circle'
                                size='large'
                                icon='setting'
                            />
                        </Popover>
                        {
                            this.permissions.includes('fmd_add_voucher') &&
                            <Button
                                type="primary"
                                className="btn btn-primary"
                                onClick={this.props.showForm} >
                                Record Voucher
                            </Button>
                        }
                    </div>
                </div>
                <Table
                    style={{ marginTop: '30px' }}
                    dataSource={data}
                    columns={columns}
                    size="middle"
                    rowKey="id"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: get(this.props, 'vouchers.meta.total', 0)
                    }}
                    onChange={this.props.onTableChange}
                />
            </div>
        )
    }
}
