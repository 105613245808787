import { createStore, applyMiddleware } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from 'shared/reducers';
import storageSession from 'redux-persist/es/storage/session';

export default function configureStore(preloadedState) {
    const migrations = {
        1: (state) => {
            return {
                ...state
            }
        }
    }

    const persistConfig = {
        key: 'root',
        version: 1,
        storage: storageSession,
        migrate: createMigrate(migrations, { debug: false }),
        whitelist: ['loggedInUser']
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const storeEnhancers = [middlewareEnhancer];
    const composedEnhancer = composeWithDevTools(...storeEnhancers);
    const store = createStore(persistedReducer, preloadedState, composedEnhancer);

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module
                .hot
                .accept('shared/reducers', () => {
                    const newRootReducer = require("shared/reducers").default;
                    store.replaceReducer(persistReducer(persistConfig, newRootReducer))
                });
        }
    }

    const persistor = persistStore(store);

    return { store, persistor };
}
