import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';
import _ from 'lodash';
const Option = Select.Option;

export default class MyComponent extends Component {
    constructor(props) {
        super(props);
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false,
        };
        this.lastFetchId = 0;
        this.fetchData = debounce(this.fetchData, 500);
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    componentDidMount = () => {
        this.fetchData('');
    }

    dataCallback = () => {
        if (this.props.getFull) {
            const lookup = _.mapKeys(this.state.data, 'id');
            if (this.props.value) {
                const result = this.props.value.map((item) => {
                    return lookup[item.id];
                });
                if (this.props.onChange) {
                    this.props.onChange(result);
                }

            }
        }
    }

    clear = () => {
        this.setState({ value: undefined });
    }

    checkIfPresent = (id) => {
        if (this.props.getFull) {
            return _.some(this.state.value, (item) => { return item.id === id });
        }
        else {
            return this.state.value.includes(id);
        }
    }

    addVat = () => {
        var vat_id = _.chain(this.state.data).find(datum => datum.account_title.startsWith('VAT')).get('id').value();
        //_.keyBy(this.state.data, 'account_title')['VAT'].id;
        // console.log(vat_id);
        // console.log(this.checkIfPresent(vat_id));
        // console.log(this.state.value);
        if (this.state.value) {
            // if (!this.checkIfPresent(vat_id)) {
            //     var newValue = this.state.value;
            //     if (this.props.getFull) { newValue.push(_.keyBy(this.state.data, 'account_title')['VAT']); }
            //     else { newValue.push(vat_id); }
            //     this.handleChange(newValue);
            // }
        }
        else if (vat_id) {
            this.handleChange([vat_id]);
        }
    }

    fetchData = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        API_CLIENT.subaccount_list({ take: 10000, q: value, is_active: [1], account_special_type: ['TAX'], sort_key: 'account_title' })
            .then((response) => {
                if (fetchId !== this.lastFetchId) { // for fetch callback order
                    return;
                }
                this.setState({ data: response.data.data, fetching: false }, this.dataCallback);
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
    }

    handleChange = (value) => {
        this.setState({ value: value });
        var result = value;
        if (this.props.getFull) {
            var lookup = _.mapKeys(this.state.data, 'id');
            result = value.map((item) => {
                return lookup[item];
            })
        }

        if (this.props.onChange) {
            this.props.onChange(result);
        }
    }

    render() {
        var { fetching, data, value } = this.state;
        if (data.length === 0) {
            value = undefined;
        }

        if (this.props.getFull) {
            if (value) {
                value = value.map((item) => {
                    return item.id;
                })
            }
        }
        return (
            <Select
                //showSearch={true}
                mode={this.props.mode}
                value={value}
                placeholder="Select Tax"
                notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                //onSearch={this.fetchData}
                onChange={this.handleChange}
                style={{ width: '100%' }}
            >
                {data.map(d => <Option key={d.id} value={d.id}>{d.account_title}</Option>)}
            </Select>
        )

    }
}