import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';
import _ from 'lodash';
const Option = Select.Option;

export default class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false
        };
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 500);
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            var value = props.value;
            console.log(value);
            if (!Number.isInteger(value)) {
                value = Number(value);
            }
            this.setState({
                value: value || undefined,
            });
            if (value) {
                var allPartners = _.keyBy(this.state.data, 'id');
                if (this.props.onPartnerSelect && allPartners[value]) {
                    this.props.onPartnerSelect(allPartners[value]);
                }
            }
        }
    }

    componentDidMount = () => {
        this.fetchUser('');
    }

    clear = () => {
        this.setState({ value: undefined });
    }

    fetchUser = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        API_CLIENT.partner_list({ take: 100000, q: value, sort_key: 'partner_name' })
            .then((response) => {
                if (fetchId !== this.lastFetchId) { // for fetch callback order
                    return;
                }
                this.setState({ data: response.data.data, fetching: false });
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
    }

    handleChange = (value) => {
        if (!Number.isInteger(value)) {
            value = Number(value);
        }
        this.setState({ value: value });
        //console.log(value);
        if (this.props.onChange) {
            console.log(value);
            this.props.onChange(value);
            if (this.props.onPartnerSelect) {
                var allPartners = _.keyBy(this.state.data, 'id');
                if (this.props.onPartnerSelect) {
                    this.props.onPartnerSelect(allPartners[value]);
                }
            }
        }
    }

    render() {
        var { fetching, data, value } = this.state;
        if (data.length === 0) {
            value = null;
        }
        console.log(data);
        console.log(value);
        data = data.filter((item) => {
            return item.is_active || item.id === value;
        });
        console.log(data);
        return (
            <Select
                showSearch={true}
                mode={this.props.mode}
                value={value}
                placeholder="Select Partner"
                notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                onSearch={this.fetchUser}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                disabled={this.props.disabled}
            >
                {data.map(d => <Option key={d.id} value={d.id}>{d.partner_name}</Option>)}
            </Select>
        )

    }
}