import React, { Component } from 'react';
import { Input, Drawer, Form, Button, Col, Row, Select, DatePicker, notification } from 'antd';
import shared_styles from 'shared/constants/styles';
import moment from 'moment';
import _ from 'lodash';
import BurAdd from './input_BurBreakdown';
import FundClusterSelect from 'modules/accounting/components/FundClusterSelect';
import AccountSelect from 'modules/accounting/components/AccountSelect';
import PartnerSelect from 'shared/components/PartnerSelect';
import { PAP_CODE_OPTIONS } from 'shared/constants/definitions';

const Option = Select.Option;

const { TextArea } = Input;

const CLASSIFICATION_OPTIONS = [
    { label: 'PS', value: 'PS' },
    { label: 'MOOE', value: 'MOOE' },
    { label: 'CO', value: 'CO' },
];


const WrappedForm = Form.create()(
    class extends Component {
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            var end_user_options = [];
            var total = 0;
            if (form.getFieldsValue().bur_breakdown) {
                form.getFieldsValue().bur_breakdown.map((item) => {
                    if (_.keyBy(PAP_CODE_OPTIONS, 'value')[item.pap_code]) {
                        end_user_options.push(..._.keyBy(PAP_CODE_OPTIONS, 'value')[item.pap_code].end_user_options);
                    }
                    if (item.amount) {
                        total = total + parseFloat(item.amount);

                    }
                    return null;
                });
                // get only the unique ones
                end_user_options = end_user_options.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
            }
            return (
                <Form layout='vertical' >
                    <Row>
                        <Col span={6}>
                            <Form.Item label="Year">
                                {
                                    getFieldDecorator('bur_year', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>

                            <Form.Item label="BUR Number">
                                {
                                    getFieldDecorator('bur_number', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input type='number ' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="BUR Date">
                                {
                                    getFieldDecorator('bur_date', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <DatePicker />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="BUR Classification">
                                {
                                    getFieldDecorator('bur_classification', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Select mode='default'>
                                            {
                                                CLASSIFICATION_OPTIONS.map(function (item) {
                                                    return <Option key={item.value} value={item.value}>
                                                        {item.label} </Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Fund cluster">
                                {
                                    getFieldDecorator('bur_fundcluster', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <FundClusterSelect />
                                    )
                                }
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="UACS Code">
                                {
                                    getFieldDecorator('bur_accounts', {
                                        rules: [{ required: true, message: 'Please input!' }],

                                    })(
                                        <AccountSelect show_detailed={true} mode='multiple' />
                                    )
                                }
                            </Form.Item>
                        </Col>


                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Payee:">
                                {
                                    getFieldDecorator('bur_partner_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <PartnerSelect />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Particulars/Mode of Procurement:">
                                {
                                    getFieldDecorator('bur_particulars', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Fund Source">
                                {
                                    getFieldDecorator('bur_breakdown', {
                                    })(
                                        <BurAdd year={this.props.form.getFieldsValue().bur_year} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}><p style={{ fontSize: 16 }}><b>Total Budget: {total}</b></p></Col>
                        <Col span={8}></Col>
                    </Row>
                    <hr />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Requisitioner:">
                                {
                                    getFieldDecorator('bur_requisitioners', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: [],
                                    })(
                                        <Select mode='multiple'>
                                            {
                                                end_user_options.map(function (item) {
                                                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Purpose/End User:">
                                {
                                    getFieldDecorator('bur_end_users', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: [],
                                    })(
                                        <Select mode='multiple'>
                                            {
                                                end_user_options.map(function (item) {
                                                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Remarks:">
                                {
                                    getFieldDecorator('bur_remarks', {
                                        rules: [{ required: false, message: 'Please input!' }],
                                        initialValue: '',
                                    })(
                                        <TextArea />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.bur && prevProps.bur !== this.props.bur) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue({
                    ...this.props.bur,
                    bur_date: moment(this.props.bur.bur_date)
                })
            }
        }
    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            // console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;
                var total = 0;
                values.bur_breakdown.map((item) => {
                    total = total + parseFloat(item.amount);
                    return null;
                });
                if (handleSubmit) {
                    console.log(values);
                    values = {
                        ...values,
                        bur_date: values.bur_date.format('YYYY-MM-DD'),
                        bur_amount: total
                    }
                    handleSubmit(values);
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    Record Budget Utilization Request
                </div>

                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
