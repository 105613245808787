import React, { Component } from 'react';
import { Table, Button, Checkbox, Popover, Input } from 'antd';
import accounting from 'accounting';
import { get } from 'lodash';
import _ from 'lodash';
const { Search } = Input;

export default class List extends Component {
    constructor(props) {
        super(props);
        this.permissions = _.get(this.props.user, 'permissions.accounting', []);
        this.state = {
            hideColumns: []
        }
    }

    columns = [{
        title: 'OP Number',
        dataIndex: 'op_number',
        key: "op_number",
        sorter: true
    },
    {
        title: 'OP Date',
        dataIndex: 'op_date',
        key: 'op_date'
    },
    {
        title: 'Amount',
        dataIndex: 'op_amount',
        key: 'op_amount',
        render: value => accounting.formatMoney(value, '₱', 2, ',', '.')
    },
    {
        title: 'Particulars',
        dataIndex: 'op_particulars',
        key: 'op_particulars'
    },
    {
        title: 'Client',
        dataIndex: 'partner_name',
        key: 'partner_name'
    },
    {
        title: 'Status',
        dataIndex: 'op_status',
        key: 'op_status',
        filters: [
            { value: 'RELEASED', text: 'RELEASED' },
            { value: 'FINALIZED', text: 'FINALIZED' },
            { value: 'DRAFT', text: 'DRAFT' },
            { value: 'PAID', text: 'PAID' }

        ]
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (text, record, index) => {
            return (
                <div>
                    {
                        (record.op_status === 'RELEASED') &&
                        <Button
                            style={{ border: 'none', color: "rgb(93 185 250 )", background: 'none' }}
                            icon="edit"
                            onClick={(e) => this.onFinalizeEditClicked(e, index)}
                        // className='editDeleteButtons'
                        />
                    }
                    {
                        (record.op_status === 'FINALIZED' || record.op_status === 'PAID') &&
                        this.permissions.includes('fmd_print_order_of_payment') &&
                        <Button
                            style={{ border: 'none', color: "rgb(93 185 250 )", background: 'none' }}
                            icon="printer"
                            onClick={(e) => this.onPrintClicked(e, index)} />
                    }
                    {
                        (record.op_status === 'DRAFT') && (!record.estimate_id) &&
                        <Button
                            style={{ border: 'none', color: "rgb(93 185 250 )", background: 'none' }}
                            icon="edit"
                            onClick={(e) => this.onEditClicked(e, index)}
                        // className='editDeleteButtons'
                        />
                    }
                </div>
            )
        },
    }
    ];

    onFinalizeEditClicked = (e, index) => {
        e.stopPropagation();
        this.props.onFinalizeEdit(index);
    }

    onEditClicked = (e, index) => {
        e.stopPropagation();
        this.props.onEdit(index);
    }

    onPrintClicked = (e, index) => {
        e.stopPropagation();
        this.props.onPrint(index);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.dataIndex))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.columns.filter(column => column.dataIndex !== 'action').map(column => {
                        const checked = !this.state.hideColumns.includes(column.dataIndex);
                        return (
                            <div key={column.dataIndex}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.dataIndex)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        var data = [];
        if (this.props.ops) {
            data = this.props.ops.data;
        }

        return (
            <div>
                <Search
                    placeholder="Search for Order of Payment"
                    onSearch={value => this.props.onSearch(value)}
                    style={{ width: 400 }} />
                <div className="pull-right">
                    <Popover

                        title='Configure Columns'
                        content={this.columnsPopOver()}
                        trigger='click'>
                        <Button
                            style={{ margin: '0px 10px' }}
                            shape='circle'
                            size='large'
                            icon='setting'
                        />
                    </Popover>
                    {
                        this.permissions.includes('fmd_add_order_of_payment') &&
                        <Button
                            type="primary"
                            className="btn btn-primary"
                            onClick={this.props.showForm} >
                            New Order of Payment
                        </Button>

                    }
                </div>
                <Table
                    columns={this.filteredColumns()}
                    dataSource={data}
                    loading={this.props.loading}
                    rowKey='id'
                    style={{ marginTop: "10px" }}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: get(this.props.ops, 'meta.total', 0)
                    }}
                    onChange={this.props.onTableChange}
                />

            </div >
        );
    }
}

