// list of specific arrays/key-value pairs

export const INITIAL_PAGINATION = { page: 1, take: 20 };
export const NO_PAGINATION = { page: 1, take: 100000 };
export const ACCOUNTS_MAPPING = {
  "1": {
    name: "Assets",
    "01": {
      name: "Cash and Cash Equivalents",
      "01": { name: "Cash on Hand" },
      "02": { name: "Cash in Bank - Local Currency" },
      "03": { name: "Cash in Bank - Foreign Currency" },
      "04": { name: "Treasury/Agency Cash Accounts" },
      "05": { name: "Cash Equivalents" }
    },
    "02": {
      name: "Investments",
      "01": {
        name: "Financial Assets at Fair Value Through Surplus or Deficit"
      },
      "02": { name: "Financial Assets - Held to Maturity" },
      "03": { name: "Financial Assets - Others" },
      "04": { name: "Investments in GOCCs" },
      "05": { name: "Investments in Joint Venture" },
      "06": { name: "Investments in Associates" },
      "07": { name: "Sinking Fund" }
    },
    "03": {
      name: "Receivables",
      "01": { name: "Loans and Receivable Accounts" },
      "02": { name: "Lease Receivable" },
      "03": { name: "Inter-Agency Receivables" },
      "04": { name: "Intra-Agency Receivables" },
      "05": { name: "Other Receivables" }
    },
    "04": {
      name: "Inventories",
      "01": { name: "Inventory Held for sale" },
      "02": { name: "Inventory Held for Distribution" },
      "03": { name: "Inventory Held for Manufacturing" },
      "04": { name: "Inventory Held for Consumption" },
      "05": { name: "Semi-Expandable Office Equipment" }
    },
    "05": {
      name: "Investment Property",
      "01": { name: "Land and Buildings" }
    },
    "06": {
      name: "Property, Plant and Equipment",
      "01": { name: "Land" },
      "02": { name: "Land Improvements" },
      "03": { name: "Infrastructure Assets" },
      "04": { name: "Buildings and Other Structures" },
      "05": { name: "Machinery and Equipment" },
      "06": { name: "Transportation Equipment" },
      "07": { name: "Furniture, Fixtures and Books" },
      "08": { name: "Leased Assets" },
      "09": { name: "Leased Assets Improvements" },
      "10": { name: "Construction in Progress" },
      "11": { name: "Heritage Assets" },
      "99": { name: "Other Property, Plant and Equipment" }
    },
    "07": {
      name: "Biological Assets",
      "01": { name: "Bearer Biological Assets" },
      "02": { name: "Consumable Biological Assets" }
    },
    "08": {
      name: "Intangible Assets",
      "01": { name: "Intangible Assets" },
      "02": { name: "Service Concession - Intangible Assets" }
    },
    "99": {
      name: "Other Assets",
      "01": { name: "Advances" },
      "02": { name: "Prepayments" },
      "03": { name: "Deposits" },
      "99": { name: "Other Assets" }
    }
  },
  "2": {
    name: "Liabilities",
    "01": {
      name: "Financial Liabilities",
      "01": { name: "Payables" },
      "02": { name: "Bills/Bonds/Loans Payable" }
    },
    "02": {
      name: "Inter-Agency Payables",
      "01": { name: "Inter-Agency Payables" }
    },
    "03": {
      name: "Intra-Agency Payables",
      "01": { name: "Intra-Agency Payables" }
    },
    "04": {
      name: "Trust Liabilities",
      "01": { name: "Trust Liabilities" }
    },
    "05": {
      name: "Deferred Credits/Unearned Income",
      "01": { name: "Deferred Credits" },
      "02": { name: "Unearned Revenue" }
    },
    "06": {
      name: "Provisions",
      "01": { name: "Provisions" }
    },
    "99": {
      name: "Other Payables",
      "99": { name: "Other Payables" }
    }
  },
  "3": {
    name: "Equity",
    "01": {
      name: "Government Equity",
      "01": { name: "Government Equity" }
    },
    "02": {
      name: "Revaluation Surplus",
      "01": { name: "Revaluation Surplus" }
    },
    "03": {
      name: "Intermediate Accounts",
      "01": { name: "Intermediate Accounts" }
    },
    "04": {
      name: "Equity In Joint Venture",
      "01": { name: "Equity In Joint Venture" }
    }
  },
  "4": {
    name: "Revenues",
    "01": {
      name: "Tax Revenue",
      "01": { name: "Tax Revenue - Individual and Corporation" },
      "02": { name: "Tax Revenue - Property" },
      "03": { name: "Tax Revenue - Goods and Services" },
      "04": { name: "Tax Revenue - Others" },
      "05": { name: "Tax Revenue - Fines and Penalties" }
    },
    "02": {
      name: "Service And Business Income",
      "01": { name: "Service Income" },
      "02": { name: "Business Income" }
    },
    "03": {
      name: "Assistance and Subsidy",
      "01": { name: "Assistance and Subsidy" }
    },
    "04": {
      name: "Shares, Grants and Donations",
      "01": { name: "Shares" },
      "02": { name: "Grants and Donations" }
    },
    "05": {
      name: "Gains",
      "01": { name: "Gains" }
    },
    "06": {
      name: "Other Non-Operating Income",
      "01": { name: "Sale of Assets" },
      "02": { name: "Reversal of Impairment Loss" },
      "09": { name: "Miscellaneous Income" }
    }
  },
  "5": {
    name: "Expenses",
    "01": {
      name: "Personnel Services",
      "01": { name: "Salaries and Wages" },
      "02": { name: "Other Compensation" },
      "03": { name: "Personnel Benefit Contributions" },
      "04": { name: "Other Personnel Benefits" }
    },
    "02": {
      name: "Maintenance and Other Operating Expenses",
      "01": { name: "Traveling Expenses" },
      "02": { name: "Training and Scholarship Expenses" },
      "03": { name: "Supplies and Materials Expenses" },
      "04": { name: "Utility Expenses" },
      "05": { name: "Communication Expenses" },
      "06": { name: "Awards/Rewards and Prizes" },
      "07": { name: "Survey, Research, Exploration and Development Expenses" },
      "08": { name: "Demolition/Relocation and Desilting/Dredging Expenses" },
      "09": { name: "Generation, Transmission and Distribution Expenses" },
      "10": { name: "Confidential, Intelligence and Extraordinary Expenses" },
      "11": { name: "Professional Services" },
      "12": { name: "General Services" },
      "13": { name: "Repairs and Maintenance" },
      "14": { name: "Financial Assistance/Subsidy" },
      "15": { name: "Taxes, Insurance Premiums and Other Fees" },
      "16": { name: "Labor and Wages" },
      "99": { name: "Other Maintenance and Operating Expenses" }
    },
    "03": {
      name: "Financial Expenses",
      "01": { name: "Financial Expenses" }
    },
    "04": {
      name: "Direct Costs",
      "01": { name: "Cost of Goods Manufactured" },
      "02": { name: "Cost of Sales" }
    },
    "05": {
      name: "Non-Cash Expenses",
      "01": { name: "Depreciation" },
      "02": { name: "Amortization" },
      "03": { name: "Impairment Loss" },
      "04": { name: "Losses" }
    },
    "06": {
      name: "Capital Outlays",
      "01": { name: "Investment Outlay" },
      "02": { name: "Loans Outlay" },
      "03": { name: "Investment Property Outlay" },
      "04": { name: "Property, Plant and Equipment Outlay" },
      "05": { name: "Biological Assets Outlay" },
      "06": { name: "Intangible Assets Outlay" }
    }
  }
};

export const IS_ACTIVE_OPTIONS = [
  { value: 0, text: "Inactive" },
  { value: 1, text: "Active" }
];

export const ACCOUNT_TYPE_OPTIONS = [
  { value: "Assets", text: "Assets" },
  { value: "Liabilities", text: "Liabilities" },
  { value: "Equity", text: "Equity" },
  { value: "Revenues", text: "Revenues" },
  { value: "Expenses", text: "Expenses" }
];

export const JOURNAL_TYPE_OPTIONS = [
  { value: "CRJ", text: "CRJ" },
  { value: "CDJ", text: "CDJ" },
  { value: "CKDJ", text: "CKDJ" },
  { value: "JEV", text: "JEV" }
];

export const PAP_CODE_OPTIONS = [
  {
    label: "A1A1",
    value: "A1A1",
    text: "A1A1",
    end_user_options: [
      { label: "Director's Office / Assistant D.O.", value: "DO" },
      { label: "Admin / Finance", value: "ADMIN" },
      {
        label: "Superintendent / Assistant Superintendent",
        value: "SUPERINTENDENT"
      }
    ]
  },
  {
    label: "A2A1",
    value: "A2A1",
    text: "A2A1",
    end_user_options: [{ label: "PPCD", value: "PPCD" }]
  },
  {
    label: "A2A2",
    value: "A2A2",
    text: "A2A2",
    end_user_options: [{ label: "Engineering", value: "Engineering" }]
  },
  {
    label: "A3A1",
    value: "A3A1",
    text: "A3A1",
    end_user_options: [
      { label: "Composing", value: "Composing" },
      { label: "Photolitography", value: "Photolitography" }
    ]
  },
  {
    label: "A3A2",
    value: "A3A2",
    text: "A3A2",
    end_user_options: [
      { label: "Press", value: "Press" },
      { label: "Finishing", value: "Finishing" }
    ]
  },
  {
    label: "A3A3",
    value: "A3A3",
    text: "A3A3",
    end_user_options: [
      { label: "Sales and Marketing", value: "Sales and Marketing" }
    ]
  }
];

export const PAP_END_USER_OPTIONS = [
  { text: "Director's Office / Assistant D.O.", value: "DO" },
  { text: "Admin / Finance", value: "ADMIN" },
  {
    text: "Superintendent / Assistant Superintendent",
    value: "SUPERINTENDENT"
  },
  { text: "PPCD", value: "PPCD" },
  { text: "Engineering", value: "Engineering" },
  { text: "Composing", value: "Composing" },
  { text: "Photolitography", value: "Photolitography" },
  { text: "Press", value: "Press" },
  { text: "Finishing", value: "Finishing" },
  { text: "Sales and Marketing", value: "Sales and Marketing" }
];
