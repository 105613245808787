import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Ops from './_op';
import Ors from './_or';
import _ from 'lodash';

const { Content } = Layout;

const MENU_ITEMS = [
    { title: 'Official Receipts', value: 'collection/or', permission: 'fmd_view_receipt' },
    { title: 'Orders of Payment', value: 'collection/op', permission: 'fmd_view_order_of_payment' }
]

class Coll extends Component {

    getMenu = () => {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <Menu
                mode="horizontal"
                style={{ fontSize: 18, color: '#9AA0A5' }}
                onClick={this.onClick_Menu}
                defaultSelectedKeys={["Official Receipts"]}>
                {
                    MENU_ITEMS.map((item) => {
                        if (item.permission && !permissions.includes(item.permission)) {
                            return null;
                        }
                        return (
                            <Menu.Item key={item.title} >
                                <NavLink to={`/${item.value}`} style={{ fontWeight: '200', color: 'black' }}>
                                    {item.title}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
    }

    render() {
        var permissions = _.get(this.props.loggedInUser, 'user.permissions.accounting', []);
        return (
            <div>
                <Layout className="layout">
                    {this.getMenu()}

                    <Content style={{ background: "white", paddingTop: '30px' }} >
                        <HashRouter>
                            <Switch>
                                {
                                    permissions.includes('fmd_view_receipt') && <Route path="/collection/or" exact component={Ors} />
                                }
                                {
                                    permissions.includes('fmd_view_order_of_payment') && <Route path="/collection/op" exact component={Ops} />
                                }
                                {
                                    permissions.includes('fmd_view_receipt') && <Route path="/collection/" exact component={Ors} />
                                }

                            </Switch>
                        </HashRouter>
                    </Content>
                </Layout>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return ({
        loggedInUser: state.loggedInUser,
    })
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Coll);

