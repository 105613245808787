import React, { Component } from 'react';
import { connect } from 'react-redux';

import { HashRouter, Route, Switch } from 'react-router-dom';
import { Col, Row, Menu, Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import GeneralLedger from './containers/general_ledger';
import GeneralJournal from './containers/general_journal';
import Ckdj from './containers/ckdj';
import Crj from './containers/crj';
import Cdj from './containers/cdj';
import SubsidiaryLedger from './containers/subsidiary_ledger';
import IndexOfPayment from './containers/index_of_payment';
import TrialBalance from './containers/trial_balance';
import FinancialStatement from './containers/statement';
import _ from 'lodash';

const { Content } = Layout;

const MENU_ITEMS = [
]

class MyComponent extends Component {

    getMenu = () => {
        return (
            <Menu
                mode="horizontal"
                style={{ fontSize: 18, color: '#9AA0A5' }}
                onClick={this.onClick_Menu}>
                {
                    MENU_ITEMS.map((item) => {
                        return (
                            <Menu.Item key={item.title} >
                                <NavLink to={`/${item.value}`} style={{ fontWeight: '200' }}>
                                    {item.title}
                                </NavLink>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        );
    }

    render() {
        return (
            <div>
                <Layout className="layout">
                    {this.getMenu()}

                    <Content style={{ background: "white", paddingTop: '30px' }} >
                        <HashRouter>
                            <Switch>
                                <Route path="/report/general_journal" component={GeneralJournal} />
                                <Route path="/report/ckdj" component={Ckdj} />
                                <Route path="/report/cdj" component={Cdj} />
                                <Route path="/report/crj" component={Crj} />
                                <Route path="/report/general_ledger" component={GeneralLedger} />
                                <Route path="/report/subsidiary_ledger" component={SubsidiaryLedger} />
                                <Route path="/report/index_of_payment" component={IndexOfPayment} />
                                <Route path="/report/trial_balance" component={TrialBalance} />
                                <Route path="/report/financial_statement" component={FinancialStatement} />
                                <Route path="/report" render={(props) => <ReportMenu {...props} user={this.props.loggedInUser.user} />} />

                            </Switch>
                        </HashRouter>

                    </Content>
                </Layout>


            </div>
        );
    }
}

class ReportMenu extends Component {
    render = () => {
        var permissions = _.get(this.props.user, 'permissions.accounting', []);
        var REPORT_ITEMS = [
            {
                permission: 'fmd_print_general_journal',
                link: '/report/general_journal', title: 'General Journal',
                description: 'This is a report of all the transactions of Finance Management Division. General Journal is considered as the initial book of entry, it holds all the accounts recorded in a certain month.'
            },
            {
                permission: 'fmd_print_ckdj',
                link: '/report/ckdj', title: 'Check Disbursement Journal',
                description: 'This is a detailed report of all the recorded disbursements through checks.'
            },
            {
                permission: 'fmd_print_cdj',
                link: '/report/cdj', title: 'Cash Disbursement Journal',
                description: 'This is a detailed report of all the recorded cash payments that had been made in a certain month.'
            },
            {
                permission: 'fmd_print_crj',
                link: '/report/crj', title: 'Cash Receipt Journal',
                description: 'This is a report of all the recorded cash receipt for collections and deposits of the Finance Management Division.'
            },
            {
                permission: 'fmd_print_general_ledger',
                link: '/report/general_ledger', title: 'General Ledger',
                description: 'This is a detailed report of all the recorded transactions of a Single Account that is based by the Fund Cluster. General Ledger is a Summary report of a Subsidiary Ledger.'
            },
            {
                permission: 'fmd_print_subsidiary_ledger',
                link: '/report/subsidiary_ledger', title: 'Subsidiary Ledger',
                description: 'Displays all the recorded transaction of a Single Account whose sub accounts when combined, equals the balance in a specific general ledger account.'
            },
            {
                permission: 'fmd_print_index_of_payment',
                link: '/report/index_of_payment', title: 'Index of Payment',
                description: 'This is a detailed report of all the payment transactions that has been made.'
            },
            {
                permission: 'fmd_print_trial_balance',
                link: '/report/trial_balance', title: 'Trial Balance',
                description: 'This report displays all the list of closing balances of ledger accounts in a certain date. Trial Balance determines if all the recorded Entries are correct.'
            },
            {
                permission: 'fmd_print_financial_statements',
                link: '/report/financial_statement', title: 'Financial Statements',
                description: 'This is a detailed results of all the Financial Activities.These Reports helps to quantify the financial strength, performance and liquidity of NPO.'
            }

        ];
        var report_items_render = [];
        var count = 0;
        for (let i = 0; i < REPORT_ITEMS.length; i++) {
            if (permissions.includes(REPORT_ITEMS[i].permission)) {
                report_items_render.push(<Col span={8}>
                    <h3><NavLink to={REPORT_ITEMS[i].link}>{REPORT_ITEMS[i].title}</NavLink></h3>
                    {REPORT_ITEMS[i].description}
                </Col>
                )
                count++;
                if (count % 3 === 0 && count !== 0) {
                    report_items_render.push(<Col span={24}><br /><br /></Col>);
                }
            }
        }
        return <div>
            <Row><h1>&nbsp;</h1></Row>
            <Row gutter={16}>
                {report_items_render}

            </Row>
        </div>

    }
}

const mapStateToProps = state => {
    return {
        loggedInUser: state.loggedInUser,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);