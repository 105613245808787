import React, { Component } from 'react';
import { Table, Icon, Input, Button, Checkbox, Popover } from 'antd';
import IsActive from 'shared/components/IsActive';
import { ACCOUNT_TYPE_OPTIONS, IS_ACTIVE_OPTIONS } from 'shared/constants/definitions';
import { get } from 'lodash';
import _ from 'lodash';

const Search = Input.Search;

export default class ChartOfAccountTable extends Component {
    constructor(props) {
        super(props);
        this.permissions = _.get(this.props.user, 'permissions.accounting', []);
        this.state = {
            hideColumns: []
        }
        this.columns = [
            {
                title: "Type",
                dataIndex: "account_type",
                sorter: true,
                filters: ACCOUNT_TYPE_OPTIONS,
            },
            {
                title: "Group",
                dataIndex: "account_group",
                sorter: true,
            },
            {
                title: "Sub-group",
                dataIndex: "account_subgroup",
                sorter: true,
            },
            {
                title: "Account Title",
                dataIndex: "account_title",
                sorter: true,
            },
            {
                title: "RCA Code",
                dataIndex: "account_rca",
                sorter: true,
            },
            {
                title: "UACS Code",
                dataIndex: "account_uacs",
                sorter: true,
            },
            {
                title: 'STATUS',
                dataIndex: 'is_active',
                editable: true,
                key: 'is_active',
                filters: IS_ACTIVE_OPTIONS,
                render: (text, record, index) => {
                    const value = record.recordStatus
                    return (
                        value === "False"
                            ?
                            <IsActive value={text} />
                            :
                            <IsActive value={text} />
                    )
                }
            },
            {
                title: "",
                dataIndex: "action",
                render: (text, record, index) => {
                    const value = record.recordAction
                    return (
                        value === "False"
                            ?
                            <div />
                            :
                            <div>
                                {
                                    this.permissions.includes('fmd_edit_account') &&
                                    <button className='link-button' onClick={() => this.edit(record)}>
                                        <Icon type="edit" style={{}} className="editDeleteButtons" />
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {
                                    // <a onClick={()=> this.delete(index)}><Icon type ="delete" style={{}}/></a>
                                }
                            </div>
                    )
                }
            }

        ]
    }

    edit = (record) => {
        this.props.onEdit(record);
    }

    delete = (index) => {
        this.props.onDelete(index);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.dataIndex))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.columns.filter(column => column.dataIndex !== 'action').map(column => {
                        const checked = !this.state.hideColumns.includes(column.dataIndex);
                        return (
                            <div key={column.dataIndex}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.dataIndex)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const columns = this.filteredColumns();
        var data = this.props.accounts.data;
        return (
            <div>
                <div>
                    <Search
                        placeholder="Search for Account"
                        onSearch={value => this.props.onSearch(value)}
                        style={{ width: 400 }} />
                    <div className="pull-right">
                        <Popover
                            title='Configure Columns'
                            content={this.columnsPopOver()}
                            trigger='click'>
                            <Button
                                style={{ margin: '0px 10px' }}
                                shape='circle'
                                size='large'
                                icon='setting'
                            />
                        </Popover>
                        {
                            this.props.canInitialize &&
                            <Button
                                type=""
                                className="btn"
                                onClick={this.props.showInitialize} >
                                Initialize Trial Balance
                        </Button>
                        }
                        &nbsp;
                        {
                            this.permissions.includes('fmd_add_account') &&

                            <Button
                                type="primary"
                                className="btn btn-primary"
                                onClick={this.props.showForm} >
                                Create Account
                            </Button>
                        }
                    </div>
                </div>
                <Table
                    style={{ marginTop: '30px' }}
                    dataSource={data}
                    columns={columns}
                    size="middle"
                    rowKey="id"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: get(this.props, 'accounts.meta.total', 0)
                    }}
                    onChange={this.props.onTableChange}
                />
            </div>
        )
    }
}
