import React, { Component } from 'react';
import { DatePicker, Form, Col, Row } from 'antd';


const WrappedForm = Form.create()(
    class extends Component {
        triggerStart = (e) => {
            this.props.onChange(e, this.props.form.getFieldsValue().end_date);
        }
        triggerEnd = (e) => {
            this.props.onChange(this.props.form.getFieldsValue().start_date, e);
        }
        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Form layout='vertical'>
                    <Row gutter={16}>
                        <Col span={4}>
                            <Form.Item >

                                {
                                    getFieldDecorator('start_date', {
                                        rules: [{ required: true, message: 'Start Date' }]
                                    })(
                                        <DatePicker placeholder="Start Date" onChange={this.triggerStart} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item >

                                {
                                    getFieldDecorator('end_date', {
                                        rules: [{ required: true, message: 'End Date' }]
                                    })(
                                        <DatePicker placeholder="End Date" onChange={this.triggerEnd} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            );
        }
    }
);

export default WrappedForm;