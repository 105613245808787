import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_FUNDCLUSTER_CREATE_ACTION = 'API_FUNDCLUSTER_CREATE_ACTION';
export const API_FUNDCLUSTER_READ_ACTION = 'API_FUNDCLUSTER_READ_ACTION';
export const API_FUNDCLUSTER_UPDATE_ACTION = 'API_FUNDCLUSTER_UPDATE_ACTION';
export const API_FUNDCLUSTER_LIST_ACTION = 'API_FUNDCLUSTER_LIST_ACTION';

export const fundcluster_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_FUNDCLUSTER_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.fundcluster_create(body),
            API_FUNDCLUSTER_CREATE_ACTION,
            callback
        );
    }
}

export const fundcluster_read = (fundcluster_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_FUNDCLUSTER_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.fundcluster_read(fundcluster_id),
            API_FUNDCLUSTER_READ_ACTION,
            callback
        );
    }
}

export const fundcluster_update = (fundcluster_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_FUNDCLUSTER_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.fundcluster_update(fundcluster_id, body),
            API_FUNDCLUSTER_UPDATE_ACTION,
            callback
        );
    }
}

export const fundcluster_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_FUNDCLUSTER_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.fundcluster_list(meta),
            API_FUNDCLUSTER_LIST_ACTION,
            callback
        );
    }
}
