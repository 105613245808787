import API_CLIENT from 'shared/utils/api_client';
import { make_api_call } from 'shared/utils/api_utils';

export const API_BANK_CREATE_ACTION = 'API_BANK_CREATE_ACTION';
export const API_BANK_READ_ACTION = 'API_BANK_READ_ACTION';
export const API_BANK_UPDATE_ACTION = 'API_BANK_UPDATE_ACTION';
export const API_BANK_DELETE_ACTION = 'API_BANK_DELETE_ACTION';
export const API_BANK_LIST_ACTION = 'API_BANK_LIST_ACTION';

export const bank_create = (body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BANK_CREATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_create(body),
            API_BANK_CREATE_ACTION,
            callback
        );
    }
}

export const bank_read = (bank_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BANK_READ_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_read(bank_id),
            API_BANK_READ_ACTION,
            callback
        );
    }
}

export const bank_update = (bank_id, body, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BANK_UPDATE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_update(bank_id, body),
            API_BANK_UPDATE_ACTION,
            callback
        );
    }
}

export const bank_delete = (bank_id, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BANK_DELETE_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_delete(bank_id),
            API_BANK_DELETE_ACTION,
            callback
        );
    }
}

export const bank_list = (meta, callback = null) => {
    return (dispatch) => {
        dispatch({ type: `${API_BANK_LIST_ACTION}_REQUEST` });
        make_api_call(dispatch,
            API_CLIENT.subaccount_list(meta),
            API_BANK_LIST_ACTION,
            callback
        );
    }
}
