import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Button, notification } from 'antd';
import CreateUpdateForm from './CreateUpdateForm';
import { createPartner, updatePartner } from '../actionCreators';

class CreateUpdate extends Component {
	handleSubmit = (values) => {
		if (this.props.data) {
			const updateValue = Object.assign(this.props.data, values);
			this.props.updatePartner(updateValue, this.successCallback, this.errorCallback)
		} else {
			this.props.createPartner(values, this.successCallback, this.errorCallback);
		}
	}

	successCallback = () => {
		this.props.onDrawerCloseClicked();
	}

	errorCallback = (error) => {
		notification['error']({ message: error.message });
	}

	render() {
		const title = this.props.data ? 'Modify Details' : 'Create Partner';
		return (
			<Drawer
				title={<h1 style={{fontSize: '36px', color: "gray", padding: "10px", fontWeight: "normal"}} > {title} </h1>}
				width={720}
				placement="right"
				onClose={this.props.onDrawerCloseClicked}
				maskClosable={false}
				destroyOnClose={true}
				visible={this.props.visible}
				style={{ height: 'calc(100% - 82px)', overflow: 'auto', paddingBottom: 80 }}
			>
				<CreateUpdateForm 
					handleSubmit={this.handleSubmit}
					data={this.props.data}
				/>
				<div style={{ position: 'absolute', backgroundColor: '#FFF', bottom: 0, width: '100%', borderTop: '1px solid #e8e8e8', padding: '20px', textAlign: 'left', left: 0, }}>
					<Button loading={this.props.loading} form="partnerForm" key="submit" htmlType="submit"style={{ marginRight: 8 }} type="primary">
						Save
					</Button>
					<Button loading={this.props.loading} onClick={this.props.onDrawerCloseClicked}>
						Cancel
					</Button>
				</div>
			</Drawer>
		);
	}
}

const mapStateToProps = ({partner}) => {
	return {
		loading: partner.loading
	};
}

const mapDispatchToProps = {
	createPartner, updatePartner
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdate);
