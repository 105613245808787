import React, { Component } from 'react';
import { Upload } from 'antd';
import { Icon } from 'antd';
import {getAuthHeader} from 'shared/utils/api_client';
const Dragger = Upload.Dragger; 

class FilesUpload extends Component {
    constructor(props) {
        super(props);
    
        const value = props.value || [];
        this.state = {
          value: value
        };
    }

    // // for handling resetFields
    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps', nextProps.value);
        this.setState({
            value: nextProps.value || []
        });  
    }

    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', this.props.value);
        if(this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value || []
            });
        }
    }
    
    onDraggerChange = (info) => {
        console.log('onDraggerChange', info);
        let {fileList} = info;
        
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.location || file.url
            }
            return file;
        })

        fileList = fileList.filter(function(file){
            // if (file.response) {
            //     console.log(file.response.status === 'success' || file.response.status === 'done');
            //     return file.response.status === 'success' || file.response.status === 'done';
            // }
            return true;
        });
        // this.setState({value: fileList});
        this.props.onChange(fileList);
    }

    render() {
        console.log('render', this.state.value);
        const uploadProps = {
            accept: '*',
            action: this.props.upload_url,
            multiple: true,
            headers: {...getAuthHeader() },
            data: {
            },
            listType: "text",
            onChange: this.onDraggerChange,
            defaultFileList: this.props.value,
            fileList: this.state.value
        }

        return <Dragger {...uploadProps} >
            <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
    }
}

export default FilesUpload;
