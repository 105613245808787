import React, { Component } from 'react';
import { Table, Icon, Input, Button, Checkbox, Popover } from 'antd';
import IsActive from 'shared/components/IsActive'
import { PAP_CODE_OPTIONS, IS_ACTIVE_OPTIONS, PAP_END_USER_OPTIONS } from 'shared/constants/definitions';
import { get } from 'lodash';
import accounting from 'accounting';
import _ from 'lodash';

const Search = Input.Search;

export default class PapTable extends Component {
    constructor(props) {
        super(props);
        this.permissions = _.get(this.props.user, 'permissions.accounting', []);
        this.state = {
            hideColumns: []
        }
        this.columns = [
            {
                title: "PAP Code",
                dataIndex: "pap_code",
                sorter: true,
                filters: PAP_CODE_OPTIONS,
                render: (text, record, index) => {
                    return text.join(', ');
                }
            },
            {
                title: "Procurement Program/Project",
                dataIndex: "pap_program",
                sorter: true
            },
            {
                title: "Mode of Procurement",
                dataIndex: "pap_mode_of_procurement",
                sorter: true
            },
            {
                title: "End Users",
                dataIndex: "pap_end_users",
                filters: PAP_END_USER_OPTIONS,
                render: (text, record, index) => {
                    return record.pap_end_users.join(', ');
                }
                // sorter: true
            },
            {
                title: "Total Budget",
                dataIndex: "pap_estimated_budget",
                sorter: true,
                render: value => accounting.formatMoney(value, '₱', 2, ',', '.')
            },
            {
                title: "Unutilized Budget",
                dataIndex: "utilized_amount",
                key: "utilized_amount",
                render: (text, record, index) => {
                    var val = parseFloat(record.pap_estimated_budget) - parseFloat(record.utilized_amount);
                    if (isNaN(val)) {
                        return accounting.formatMoney(record.pap_estimated_budget, '₱', 2, ',', '.');
                    }
                    else {
                        return accounting.formatMoney(val, '₱', 2, ',', '.');
                    }
                }
            },
            {
                title: 'STATUS',
                dataIndex: 'is_active',
                filters: IS_ACTIVE_OPTIONS,
                onFilter: (value, record) => value === record.is_active,
                render: (text, record, index) => {
                    return <IsActive value={text} key={record.id} />
                }
            },
            {
                title: "",
                dataIndex: "action",
                key: "action",
                render: (value, record, index) => {
                    // const length = record.children.length
                    const valueRecord = record.editIcon;
                    return (
                        valueRecord === false
                            ?
                            <div />
                            :
                            <div>
                                {
                                    this.permissions.includes('fmd_edit_pap') &&
                                    <button className='link-button' onClick={() => this.edit(record)}>
                                        <Icon type="edit" style={{}} className="editDeleteButtons" />
                                    </button>
                                }
                                &nbsp;&nbsp;
                                {
                                    //<a onClick={()=> this.delete(index)}><Icon type ="delete" style={{}}/></a>
                                }
                            </div>
                    )
                }
            }
        ]
    }

    edit = (record) => {
        this.props.onEdit(record);
    }

    delete = (index) => {
        this.props.onDelete(index);
    }

    filteredColumns = () => {
        return this.columns.filter(column => !this.state.hideColumns.includes(column.dataIndex))
    }

    toggleColumn = (column) => {
        const columns = [...this.state.hideColumns];
        const index = columns.indexOf(column);
        if (index >= 0) {
            columns.splice(index, 1);
        } else {
            columns.push(column);
        }
        this.setState({ hideColumns: columns })
    }

    columnsPopOver = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    this.columns.filter(column => column.dataIndex !== 'action').map(column => {
                        const checked = !this.state.hideColumns.includes(column.dataIndex);
                        return (
                            <div key={column.dataIndex}>
                                <Checkbox
                                    onChange={e => this.toggleColumn(column.dataIndex)}
                                    checked={checked}
                                >
                                    {column.title}
                                </Checkbox>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        const columns = this.filteredColumns();
        const data = this.props.paps.data;
        console.log('data', data)
        return (
            <div>
                <div>
                    <Input style={{ width: 100, marginRight: 10 }}
                        onChange={this.props.onChangeYear}
                        value={this.props.q_year} />
                    <Search
                        placeholder="Search for PAP"
                        onSearch={value => this.props.onSearch(value)}
                        style={{ width: 400 }} />
                    <div className="pull-right">
                        <Popover
                            title='Configure Columns'
                            content={this.columnsPopOver()}
                            trigger='click'>
                            <Button
                                style={{ margin: '0px 10px' }}
                                shape='circle'
                                size='large'
                                icon='setting'
                            />
                        </Popover>
                        {
                            this.permissions.includes('fmd_add_pap') &&
                            <Button
                                type="primary"
                                className="btn btn-primary"
                                onClick={this.props.showForm} >
                                Create PAP
                            </Button>
                        }

                    </div>
                </div>
                <Table
                    style={{ marginTop: '30px' }}
                    dataSource={data}
                    columns={columns}
                    size="middle"
                    rowKey="id"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30', '40', '50'],
                        total: get(this.props, 'paps.meta.total', 0)
                    }}
                    onChange={this.props.onTableChange}
                />
            </div>
        )
    }
}
