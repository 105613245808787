import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './containers/List';

class Partners extends Component {
    render() {
		return (
			<Switch>
				{
					// <Route exact path="/users/new" component={CreateEditUser} />
					// <Route path="/users/edit/:id" component={CreateEditUser} />
					// <Route path="/users/:id" component={ViewUser} />
					<Route exact path="/partner" component={List} />
				}
			</Switch>
		);
    }
}

export default Partners;
