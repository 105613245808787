import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';
const Option = Select.Option;

export default class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false,
            filter: ''
        };
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 500);
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    componentDidMount = () => {
        this.fetchUser('');
    }

    clear = () => {
        this.setState({ value: undefined });
    }

    fetchUser = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        API_CLIENT.fundcluster_list({ take: 200, q: value, is_active: [1] })
            .then((response) => {
                if (fetchId !== this.lastFetchId) { // for fetch callback order
                    return;
                }
                this.setState({ data: response.data.data, fetching: false });
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
    }

    handleChange = (value) => {
        this.setState({ value: value });
        //console.log(value);
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    filterOptions = (value) => {
        this.setState({ filter: value })
    }
    filterOptionsClear = () => {
        this.setState({ filter: '' })
    }

    render() {
        var { fetching, data, value } = this.state;
        //console.log(data);
        //console.log(value);
        if (this.state.filter) {
            data = data.filter((item) => {
                if (item.id.toString().includes(this.state.filter)) {
                    return true;
                }
                else {
                    return false;
                }
            });
        }
        return (
            <Select
                showSearch={false}
                mode={this.props.mode}
                value={value}
                placeholder="Select Fund Cluster"
                notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                filterOption={false}
                onSearch={this.filterOptions}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                onBlur={this.filterOptionsClear}
            >
                {data.map(d => <Option key={d.id} value={d.id}>{d.id}</Option>)}
            </Select>
        )

    }
}