import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import accounting from 'accounting';
import { Input, Col, Row, Select, notification, Icon } from 'antd';
import API_CLIENT from 'shared/utils/api_client';
import { account_list_all } from 'modules/accounting/actions/account_actions';
const Option = Select.Option;


class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || [];
        this.state = {
            value: value,
            partner_options: null,
            account_options: null
        };
    }

    // /for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || [],
            });
        }
    }

    componentDidMount = () => {
        // FETCH values to use for partners and all accounts
        API_CLIENT.partner_list({ take: 100000, is_active: [1] })
            .then((response) => {
                console.log("assss", response.data.data);
                this.setState({ partner_options: response.data.data });
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
        this.props.account_list_all({}, (data) => {
            if (data.data) {
                this.setState({ account_options: data.data });
            }
            else {
                notification['error']({ message: data.message });
            }
        })
    }

    clear = () => {
        this.setState({ value: [] });
    }

    handleChange = (value) => {
        // propagates the change to the form

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    addOne = () => {
        const arr = this.state.value;
        arr.push([{ items: [] }]);
        this.setState({ value: arr });
    }
    removeTransaction = (index) => {
        const arr = this.state.value;
        arr.splice(index, 1);
        this.setState({ value: arr });
        this.props.onChange(arr);
    }
    updateTransaction = (index, value) => {
        const arr = this.state.value;
        arr[index] = value;
        this.setState({ value: arr });
        this.props.onChange(arr);
    }


    render() {
        //console.log(data);
        //console.log(value);
        if (!(this.state.account_options && this.state.partner_options)) {
            return <div></div>
        }
        let total_credit = 0;
        let total_debit = 0;
        this.state.value.map((transaction, index) => {
            if (transaction.items) {
                transaction.items.map((item) => {
                    total_debit = total_debit + (item.debit ? parseFloat(item.debit) : 0);
                    total_credit = total_credit + (item.credit ? parseFloat(item.credit) : 0);
                    return null;
                })
            }
            return null;
        })
        var style = null;
        if (total_credit !== total_debit) {
            style = { color: "red" };
        }
        return (
            <div>
                <Row gutter={16} style={{ display: this.props.isInitialize ? 'none' : 'visible' }}>
                    <Col span={1}></Col>
                    <Col span={3}>Reference</Col>
                    <Col span={6}>Particulars</Col>
                    <Col span={3}></Col>
                    <Col span={3}></Col>
                    <Col span={1}></Col>
                    <Col span={3}>Debit</Col>
                    <Col span={3}>Credit</Col>
                    <Col span={1}></Col>
                </Row>
                <hr />

                {
                    this.state.value.map((item, index) => {
                        return <TransactionInput
                            isInitialize={this.props.isInitialize}
                            partner_actions={this.state.partner_actions}
                            onClose={() => { this.removeTransaction(index) }}
                            value={item}
                            onChange={(value) => this.updateTransaction(index, value)}
                            partner_options={this.state.partner_options}
                            account_options={this.state.account_options}
                            source={this.props.source}
                            key={index} />
                    })
                }
                <Row gutter={16} style={{ marginTop: 16 }}>
                    <Col span={1}></Col>
                    {
                        this.props.source === 'MANUAL' && this.props.isInitialize !== true &&
                        <Col span={23}><button className="link-button" onClick={this.addOne}>+ Add Journal Transaction</button></Col>

                    }

                </Row>
                <hr />
                {
                    this.state.value.length > 0 &&
                    <Row gutter={16} style={{ marginTop: 16 }}>

                        <Col span={1}></Col>
                        <Col span={16}><h3>TOTAL</h3></Col>
                        <Col span={3}><h3 style={style}>{accounting.formatMoney(total_debit, '₱', 2, ',', '.')}</h3></Col>
                        <Col span={3}><h3 style={style}>{accounting.formatMoney(total_credit, '₱', 2, ',', '.')}</h3></Col>
                        <Col span={1}></Col>


                    </Row>
                }
            </div>
        )

    }
}

function mapStateToProps(state, ownProps) {
    return {

    };
}

const mapDispatchToProps = {
    account_list_all
}

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);


const DEFAULT_ITEM = {
    debit: 0,
    credit: 0
}

class TransactionInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_entity_selection: false
        }
    }

    expandItems = () => {
        console.log(this.expandItems);
    }
    onChange_reference = (e) => {
        this.props.onChange({ ...this.props.value, journaltransaction_reference: e.target.value });
    }
    onChange_particulars = (e) => {
        this.props.onChange({ ...this.props.value, journaltransaction_particulars: e.target.value });
    }
    onChange_entity_type = (e) => {
        this.props.onChange({ ...this.props.value, journaltransaction_entity_type: e.target.value });
    }
    onChange_entity_id = (value) => {
        this.props.onChange({ ...this.props.value, journaltransaction_entity_id: value });
    }


    addOneItem = () => {
        const arr = this.props.value.items || [];
        arr.push({ ...DEFAULT_ITEM });
        this.props.onChange({ ...this.props.value, items: arr })
    }
    removeItem = (index) => {
        const arr = this.props.value.items;
        arr.splice(index, 1);
        this.props.onChange({ ...this.props.value, items: arr })
    }
    updateItem = (index, value) => {
        const arr = this.props.value.items;
        arr[index] = value;
        this.props.onChange({ ...this.props.value, items: arr })
    }

    removeEntity = () => {
        this.props.onChange({ ...this.props.value, journaltransaction_entity_id: null, journaltransaction_entity_type: null });
        this.setState({ show_entity_selection: false });
    }

    addEntity = () => {
        this.props.onChange({ ...this.props.value, journaltransaction_entity_type: 'PARTNER' });
        this.setState({ show_entity_selection: true });
    }

    render = () => {
        var debit_total = 0;
        var credit_total = 0;

        if (this.props.value.items) {
            this.props.value.items.map((item) => {
                debit_total = debit_total + (item.debit ? parseFloat(item.debit) : 0);
                credit_total = credit_total + (item.credit ? parseFloat(item.credit) : 0)
                return null;
            });
        }


        return <div style={{ 'paddingBottom': '5px', 'borderBottom': '2px solid #888', 'marginBottom': '10px' }}>
            <Row gutter={16} style={{ display: this.props.isInitialize ? 'none' : 'visible' }}>
                <Col span={1}>
                    {
                        // <Icon type="down" onClick={this.expandItems} />
                    }
                </Col>
                <Col span={3}>
                    <Input disabled={this.props.source === 'VOUCHER' || this.props.source === 'OFFICIAL_RECEIPT'}
                        onChange={(value) => { this.onChange_reference(value) }} value={this.props.value.journaltransaction_reference} />
                </Col>
                <Col span={6}>
                    <Input disabled={this.props.source === 'VOUCHER' || this.props.source === 'OFFICIAL_RECEIPT'}
                        onChange={(value) => { this.onChange_particulars(value) }} value={this.props.value.journaltransaction_particulars} />
                </Col>
                <Col span={5}>
                    {
                        (this.state.show_entity_selection || this.props.value.journaltransaction_entity_id) &&
                        <PartnerSelect
                            disabled={this.props.source === 'VOUCHER' || this.props.source === 'OFFICIAL_RECEIPT'}
                            onChange={(value) => { this.onChange_entity_id(value) }}
                            value={this.props.value.journaltransaction_entity_id}
                            partner_options={this.props.partner_options} />
                    }
                    {
                        !(this.state.show_entity_selection || this.props.value.journaltransaction_entity_id) &&
                        <p style={{ marginTop: 6 }}><button className="link-button" onClick={this.addEntity}>Set Partner</button></p>
                    }
                </Col>
                <Col span={1}>
                    {
                        (this.state.show_entity_selection || this.props.value.journaltransaction_entity_id) &&
                        <Input onChange={(value) => { this.onChange_entity_type(value) }} value={this.props.value.journaltransaction_entity_type} style={{ display: 'none' }} />
                    }
                </Col>
                <Col span={1} >
                    {
                        (this.state.show_entity_selection || this.props.value.journaltransaction_entity_id) &&
                        !(this.props.source === 'VOUCHER' || this.props.source === 'OFFICIAL_RECEIPT') &&
                        <p style={{ paddingTop: 6 }}><Icon type="close" style={{ color: "red" }} onClick={this.removeEntity} /></p>
                    }
                </Col>
                <Col span={3}>
                    <Input disabled value={debit_total} />
                </Col>
                <Col span={3}>
                    <Input disabled value={credit_total} />
                </Col>
                <Col span={1} style={{ paddingTop: 6 }}>
                    <Icon type="close" style={{ color: "red" }} onClick={this.props.onClose} />
                </Col>
            </Row>
            <Row gutter={16}>

            </Row>
            <Col span={1}>
            </Col>
            <Col span={23}>
                <hr />
            </Col>
            <Row gutter={16} style={{ marginTop: 12 }}>
                <Col span={1}></Col>
                <Col span={16}>Account</Col>
                <Col span={3}>Debit</Col>
                <Col span={3}>Credit</Col>
                <Col span={1} style={{ paddingTop: 6 }} ></Col>
            </Row>

            {
                this.props.value.items &&
                this.props.value.items.map((item, index) => {
                    return <ItemInput
                        onClose={() => { this.removeItem(index) }}
                        value={item}
                        onChange={(value) => this.updateItem(index, value)}
                        key={index}
                        account_options={this.props.account_options} />
                })
            }
            <Row>
                <Col span={1}>
                </Col>
                <Col span={23}>
                    <hr />
                    <button className="link-button" onClick={this.addOneItem}>+ Add Account</button>

                </Col>

            </Row>


        </div>
    }


}



class ItemInput extends Component {
    onChange_account_id = (value) => {
        this.props.onChange({ ...this.props.value, journalitem_account_id: value });
    }
    onChange_debit = (e) => {
        this.props.onChange({ ...this.props.value, debit: e.target.value });
    }
    onChange_credit = (e) => {
        this.props.onChange({ ...this.props.value, credit: e.target.value });
    }

    render = () => {
        return <div>
            <Row gutter={16} style={{ marginTop: 6 }}>
                <Col span={1}></Col>
                <Col span={16}>
                    <AccountSelect
                        onChange={(value) => { this.onChange_account_id(value) }}
                        value={this.props.value.journalitem_account_id}
                        account_options={this.props.account_options} />
                </Col>
                <Col span={3}>
                    <Input onChange={(value) => { this.onChange_debit(value) }} value={this.props.value.debit} />
                </Col>
                <Col span={3}>
                    <Input onChange={(value) => { this.onChange_credit(value) }} value={this.props.value.credit} />
                </Col>
                <Col span={1} style={{ paddingTop: 6 }} >
                    <Icon type="close" style={{ color: "red" }} onClick={this.props.onClose} />
                </Col>
            </Row>
        </div>
    }
}

class PartnerSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onChange = (value) => {
        this.props.onChange(value);
    }

    render = () => {
        return (
            <Select
                showSearch={true}
                mode="default"
                value={this.props.value}
                placeholder="Select Partner"
                filterOption={(input, option) => {
                    let children = option.props.children;
                    if (Array.isArray(children)) {
                        children = children.join('');
                    }
                    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                onChange={this.onChange}
                disabled={this.props.disabled}
            >
                {this.props.partner_options.map(d => <Option key={d.id} value={d.id}>{d.partner_name}</Option>)}
            </Select>
        )

    }
}

class AccountSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onL1Change = (value) => {
        this.props.onChange(value);
    }

    onL2Change = (value) => {
        this.props.onChange(value);
    }

    render = () => {
        var l1_options = _.filter(this.props.account_options, function (item) {
            return item.parent_account_id === null
        });
        var lookup = _.keyBy(this.props.account_options, 'id');
        var l1_value, l2_value;

        if (lookup[this.props.value] && lookup[this.props.value].parent_account_id !== null) {
            l1_value = lookup[this.props.value].parent_account_id;
            l2_value = this.props.value;
        }
        else {
            l1_value = this.props.value;
        }
        if (lookup[l1_value]) {
            var l2_options = _.filter(this.props.account_options, (item) => {
                return lookup[l1_value].id === item.parent_account_id
            });
        }
        return (
            <div>
                <Select
                    showSearch={true}
                    mode="default"
                    value={l1_value}
                    placeholder="Select Account"
                    filterOption={(input, option) => {
                        let children = option.props.children;
                        if (Array.isArray(children)) {
                            children = children.join('');
                        }
                        return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    onChange={this.onL1Change}
                    style={{ width: '55%' }}
                >

                    {l1_options.map(d => <Option key={d.id} value={d.id}>{d.account_title} - {d.account_uacs}</Option>)}

                </Select>&nbsp;

                 {console.log("ssss", l2_options)}

                {
                    l1_value && !_.isEmpty(l2_options) &&
                    <Select
                        showSearch={true}
                        mode="default"
                        value={l2_value}
                        placeholder="Select Sub Account"
                        filterOption={(input, option) => {
                            let children = option.props.children;
                            if (Array.isArray(children)) {
                                children = children.join('');
                            }
                            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={this.onL2Change}
                        style={{ width: '35%' }}
                    >
                        {l2_options.map(d => <Option key={d.id} value={d.id}>{d.account_title}</Option>)}
                    </Select>

                }
            </div>
        )
    }
}