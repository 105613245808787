import React, { Component } from 'react';
import { Select, Spin, notification } from 'antd';
import debounce from 'lodash/debounce';
import API_CLIENT from 'shared/utils/api_client';
import _ from 'lodash';
const Option = Select.Option;

export default class MyComponent extends Component {

    constructor(props) {
        super(props);
        const value = props.value || undefined;
        this.state = {
            value: value,
            data: [],
            fetching: false
        };
        this.lastFetchId = 0;
        this.fetchData = debounce(this.fetchData, 500);
    }

    // // for handling resetFields
    componentWillReceiveProps(props) {
        if ('value' in props) {
            this.setState({
                value: props.value || undefined,
            });
        }
    }

    componentDidMount = () => {
        this.fetchData('');
    }

    clear = () => {
        this.setState({ value: undefined });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.value !== this.props.value) {
            this.fetchData(this.props.value);
        }
    }

    fetchData = (value) => {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        API_CLIENT.bur_list({ take: 20, q: value, sort_key: 'bur_number', sort_dir: 'desc' })
            .then((response) => {
                if (fetchId !== this.lastFetchId) { // for fetch callback order
                    return;
                }
                this.setState({ data: response.data.data, fetching: false, value: this.state.value });
            })
            .catch((error) => {
                //console.log(error);
                notification['error']({ message: error.message });
            });
    }

    handleChange = (value) => {
        this.setState({ value: value });
        //console.log(value);
        if (this.props.onChange) {
            this.props.onChange(value);
            if (this.props.onChange_withObject) {
                this.props.onChange_withObject(_.keyBy(this.state.data, 'bur_number')[value]);
            }
        }
    }

    render() {
        var { fetching, data, value } = this.state;
        if (data.length === 0) {
            value = null;
        }
        //console.log(data);
        //console.log(value);

        return (
            <Select
                showSearch={true}
                mode={this.props.mode}
                value={value}
                placeholder="Select BUR Number"
                notFoundContent={fetching ? <Spin size="small" /> : 'Type to search'}
                filterOption={false}
                onSearch={this.fetchData}
                onChange={this.handleChange}
                style={{ width: '100%' }}
                labelInValue={this.props.labelInValue || false}
                disabled={this.props.disabled}
                allowClear={true}
            >
                {data.map(d => <Option key={d.bur_number} value={d.bur_number}>{d.bur_number}</Option>)}
            </Select>
        )

    }
}