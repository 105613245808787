import React, { Component } from 'react';
import { connect } from 'react-redux';

import FundClusterTable from './components/FundClusterTable';
import { fundcluster_list, fundcluster_create, fundcluster_update } from '../actions/fundcluster_actions';
import { notification } from 'antd';
import { NO_PAGINATION } from 'shared/constants/definitions';

class FundClusters extends Component {
    componentDidMount() {
        this.props.fundcluster_list(NO_PAGINATION);
    }

    onSearch = (q) => {
        this.props.fundcluster_list({ ...NO_PAGINATION, ...this.props.fundclusters.meta, q: q });
        this.fundclusterTable.reset();
    }

    onCreate = (item) => {
        //console.log("TODO: create: ", item);
        this.props.fundcluster_create(item, data => {
            if (data.fundcluster) {
                notification['success']({ message: 'Created!' });
                this.fundclusterTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        });
    }

    onUpdate = (item) => {
        this.props.fundcluster_update(item.id, item, data => {
            if (data.fundcluster) {
                notification['success']({ message: 'Updated!' });
                this.fundclusterTable.reset();
            }
            else {
                notification['error']({ message: data.message });
            }
        })
    }

    onDelete = (item) => {
        console.log("TODO: delete: ", item);
    }

    render() {
        return (
            <FundClusterTable
                fundclusters={this.props.fundclusters}
                onSearch={this.onSearch}
                ref={(ref) => { this.fundclusterTable = ref; }}
                onCreate={this.onCreate}
                onUpdate={this.onUpdate}
                onDelete={this.onDelete}
                user={this.props.user}
            />
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        fundclusters: state.fundclusterListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    fundcluster_list, fundcluster_create, fundcluster_update
}

export default connect(mapStateToProps, mapDispatchToProps)(FundClusters);
