import React, { Component } from 'react';
import { Input, Switch, Drawer, Form, Button, Col, Row, notification, Radio } from 'antd';
import shared_styles from 'shared/constants/styles';
import { ACCOUNTS_MAPPING } from 'shared/constants/definitions';
import FundClusterSelect from '../../components/FundClusterSelect';
import DummyInput from 'shared/components/DummyInput';
import _ from 'lodash';

const normal_balance_options = [
    { label: 'Debit', value: 'DEBIT' },
    { label: 'Credit', value: 'CREDIT' },
];


const WrappedForm = Form.create()(
    class extends Component {
        expandIds = (val, key) => {
            // console.log(val.target.value);
            var values = { ...this.props.form.getFieldsValue(), [key]: val.target.value };
            var account_type = ACCOUNTS_MAPPING[values.account_type_id];
            var account_group = null;
            var account_subgroup = null;
            if (account_type) {
                account_group = account_type[values.account_group_id];
            }
            if (account_group) {
                account_subgroup = account_group[values.account_subgroup_id];
            }
            this.props.form.setFieldsValue({
                'account_type': account_type ? account_type.name : '-',
                'account_group': account_group ? account_group.name : '-',
                'account_subgroup': account_subgroup ? account_subgroup.name : '-'
            });
            // console.log(values);
        }

        render() {
            const { form } = this.props;
            const { getFieldDecorator } = form;
            const isEditing = null; //form.getFieldsValue().id != null;
            var permissions = _.get(this.props.user, 'permissions.accounting', []);

            return (
                <Form layout='vertical'>
                    {
                        getFieldDecorator('id', {
                            rules: [{ required: false, message: 'Please input!' }],
                            initialValue: null,
                        })(
                            <DummyInput />
                        )
                    }

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item >
                                <label style={{ marginRight: 15 }}>Status</label>
                                {
                                    getFieldDecorator('is_active', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: true,
                                        valuePropName: 'checked'
                                    })(
                                        <Switch disabled={!permissions.includes('fmd_deactivate_account')} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={14}>
                            <Form.Item label="RCA Code">
                                {
                                    getFieldDecorator('account_type_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <Input disabled={isEditing} style={{ width: 60, marginRight: 10 }} onChange={(val) => { this.expandIds(val, 'account_type_id') }} />
                                    )
                                }
                                {
                                    getFieldDecorator('account_group_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <Input disabled={isEditing} style={{ width: 60, marginRight: 10 }} onChange={(val) => { this.expandIds(val, 'account_group_id') }} />
                                    )
                                }
                                {
                                    getFieldDecorator('account_subgroup_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <Input disabled={isEditing} style={{ width: 60, marginRight: 10 }} onChange={(val) => { this.expandIds(val, 'account_subgroup_id') }} />
                                    )
                                }
                                {
                                    getFieldDecorator('account_object_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <Input disabled={isEditing} style={{ width: 60, marginRight: 10 }} onChange={(val) => { this.expandIds(val, 'account_object_id') }} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="UACS Sub-object Code">
                                {
                                    getFieldDecorator('account_subobject_id', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null,
                                    })(
                                        <Input disabled={isEditing} style={{ width: 50, marginRight: 10 }} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Account Title">
                                {
                                    getFieldDecorator('account_title', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null

                                    })(
                                        <Input />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <label>Type</label>
                        </Col>
                        <Col span={18}>
                            {this.props.form.getFieldValue('account_type')}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <label>Group</label>
                        </Col>
                        <Col span={18}>
                            {this.props.form.getFieldValue('account_group')}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6} style={{ textAlign: 'right' }}>
                            <label>Sub-group</label>
                        </Col>
                        <Col span={18}>
                            {this.props.form.getFieldValue('account_subgroup')}
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Fund Clusters">
                                {
                                    getFieldDecorator('account_fundclusters', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null
                                    })(
                                        <FundClusterSelect mode='multiple' />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Normal Balance">
                                {
                                    getFieldDecorator('account_normal_balance', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null

                                    })(
                                        <Radio.Group>
                                            {
                                                normal_balance_options.map((item) => {
                                                    return <Radio.Button value={item.value} key={item.value}>{item.label}</Radio.Button>
                                                })
                                            }
                                        </Radio.Group>

                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item style={{ display: 'none' }}>
                                {
                                    getFieldDecorator('account_type', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null

                                    })(
                                        <Input disabled={true} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{ display: 'none' }}>
                                {
                                    getFieldDecorator('account_group', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null

                                    })(
                                        <Input disabled={true} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ display: 'none' }}>
                            <Form.Item>
                                {
                                    getFieldDecorator('account_subgroup', {
                                        rules: [{ required: true, message: 'Please input!' }],
                                        initialValue: null

                                    })(
                                        <Input disabled={true} />
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            );
        }
    }
);


class DrawerForm extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.account && prevProps.account !== this.props.account) {
            if (this.formRef) {
                this.formRef.props.form.setFieldsValue(this.props.account)
            }
        }
    }

    handleSubmit = () => {
        // console.log('handleSubmit');
        this.formRef.props.form.validateFields((err, values) => {
            // console.log(err, values);
            if (!err) {
                const handleSubmit = this.props.onSubmit;
                if (values.account_type === '-' || values.account_group === '-' || values.account_subgroup === '-') {
                    notification['error']({ message: 'Please input a valid RCA Code' });
                }
                else {
                    if (handleSubmit) {
                        //console.log(values);
                        handleSubmit(values);
                    }
                }
            }
            else {
                notification['error']({ message: 'Please input all values' });
            }
        });
    }

    reset = () => {
        this.formRef.props.form.resetFields();
    }

    render() {
        return (
            <Drawer
                width={600}
                placement="right"
                onClose={this.props.onCancel}
                maskClosable={false}
                visible={this.props.visible}
                style={shared_styles.drawer}
            >
                <div style={shared_styles.drawer_top}>
                    Create Account
                </div>
                <div>
                    <WrappedForm
                        wrappedComponentRef={(formRef) => { this.formRef = formRef; }}
                        user={this.props.user}
                    />
                </div>

                <div style={shared_styles.drawer_bottom}>

                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button onClick={this.handleSubmit} type="primary">Submit</Button>
                </div>
            </Drawer>
        );
    }
}

export default DrawerForm;
