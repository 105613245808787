import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import FinalizeOPForm from './components/form_FinalizeOP';
import OPForm from './components/form_OP';
import OPTable from './components/OPTable';
import { op_list, op_finalize, op_read, op_create, op_update } from '../actions/op_actions';
import { INITIAL_PAGINATION } from 'shared/constants/definitions';
import { download_url } from 'shared/utils/api_utils';

class Ops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_visible: false,
            finalize_form_visible: false,
            currentFinalizeEdit: null,
            currentEdit: null
        }
        this.DEFAULT_LIST_PARAMS = {
            ...INITIAL_PAGINATION, sort_key: 'op_number', sort_dir: 'descend'
        }
    }

    componentDidMount() {
        this.props.op_list({ ...this.DEFAULT_LIST_PARAMS });
    }

    onSearch = (q) => {
        this.props.op_list({
            ...this.props.ops.meta,
            q: q,
        });
    }

    onPrint = (index) => {
        download_url(
            '/order_of_payment/' +
            this.props.ops.data[index].id +
            '/pdf'
            , '' + this.props.ops.data[index].op_number + '.pdf');
    }

    showForm = () => {
        this.setState({ form_visible: true });
    };
    hideForm = () => {
        this.form.reset();
        this.setState({ form_visible: false, currentEdit: null });
    };
    onSubmit = (values) => {
        console.log('onSubmit', values);
        if (this.state.currentEdit) {
            this.props.op_update(this.state.currentEdit.id, values, this.callbackSubmit);
        }
        else {
            this.props.op_create(values, this.callbackSubmit);
        }
    }
    callbackSubmit = (data) => {
        if (data.order_of_payment) {
            notification['success']({ message: 'Success!' });
            this.hideForm();
        }
        else {
            notification['error']({ message: data.message });
        }
    }
    onEdit = (index) => {
        this.setState({ form_visible: true, currentEdit: this.props.ops.data[index] })
    }

    showFinalizeForm = () => {
        this.setState({ finalize_form_visible: true });
    };
    hideFinalizeForm = () => {
        this.finalize_form.reset();
        this.setState({ finalize_form_visible: false, currentFinalizeEdit: null });
    };
    onFinalizeSubmit = (values) => {
        console.log('onFinalizeSubmit', values);
        if (this.state.currentFinalizeEdit) {
            this.props.op_finalize(this.state.currentFinalizeEdit.id, values, this.callbackFinalizeSubmit);
        }
        else {
            console.log('Submit if only for FINALIZE. This should not happen');
        }
    }
    callbackFinalizeSubmit = (data) => {
        console.log(data);
        if (data.order_of_payment) {
            notification['success']({ message: 'Success!' });
            this.hideFinalizeForm();
        }
        else {
            notification['error']({ message: data.message });
        }
    }
    onFinalizeEdit = (index) => {
        this.setState({ finalize_form_visible: true, currentFinalizeEdit: this.props.ops.data[index] })
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.props.op_list({
            ...this.props.ops.meta,
            page: pagination.current,
            take: pagination.pageSize,
            ...filters,
            sort_dir: sorter.order || 'descend',
            sort_key: sorter.columnKey
        });
    }

    render() {
        return (<div>
            <OPTable
                ops={this.props.ops}
                onFinalizeEdit={this.onFinalizeEdit}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onTableChange={this.handleTableChange}
                onSearch={this.onSearch}
                showForm={this.showForm}
                onPrint={this.onPrint}
                user={this.props.user}
            />

            <OPForm
                visible={this.state.form_visible}
                onCancel={this.hideForm}
                onSubmit={this.onSubmit}
                order_of_payment={this.state.currentEdit}
                ref={(ref) => { this.form = ref }}
            />
            <FinalizeOPForm
                visible={this.state.finalize_form_visible}
                onCancel={this.hideFinalizeForm}
                onSubmit={this.onFinalizeSubmit}
                data={this.state.currentFinalizeEdit}
                successCallback={this.onCreateSuccess}
                ref={(ref) => { this.finalize_form = ref }}
            />

        </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ops: state.opListReducer,
        user: state.loggedInUser.user
    };
}

const mapDispatchToProps = {
    op_list, op_read, op_finalize, op_create, op_update
}

export default connect(mapStateToProps, mapDispatchToProps)(Ops);
