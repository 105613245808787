import { API_BANK_LIST_ACTION, API_BANK_UPDATE_ACTION, API_BANK_CREATE_ACTION, API_BANK_DELETE_ACTION } from '../actions/bank_actions'

export const bankListReducer = (state = {}, action) => {
    var newData;
    switch (action.type) {
        //case `${API_CASE_LIST_ACTION}_REQUEST`:
        //  return { ...state, meta: action.payload };
        case `${API_BANK_LIST_ACTION}_SUCCESS`: {
            return {
                ...state,
                data: action.payload.data,
                meta: {
                    ...state.meta,
                    total: action.payload.total,
                    page: action.payload.page,
                    take: action.payload.take,
                    q: action.payload.q,
                }
            };
        }
        case `${API_BANK_CREATE_ACTION}_SUCCESS`: {
            newData = state.data;
            newData.push(action.payload.account);
            return {
                ...state,
                data: newData
            };
        }
        case `${API_BANK_UPDATE_ACTION}_SUCCESS`: {
            newData = state.data;
            let response = action.payload.account;
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === response.id) {
                    newData[i] = response;
                }
            }
            return {
                ...state,
                data: newData
            };
        }
        case `${API_BANK_DELETE_ACTION}_SUCCESS`: {
            newData = state.data;
            var indexToDelete;
            let response = action.payload.account;
            for (let i = 0; i < newData.length; i++) {
                if (newData[i].id === response.id) {
                    indexToDelete = i
                }
            }
            newData.splice(indexToDelete, 1);
            return {
                ...state,
                data: newData
            };
        }
        default:
            return state;
    }
}